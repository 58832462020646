import  React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';



import All_chettiong from './Coustomer_order_allget/Setting';

import Orderall from '../Allorder/Coustomer_order_allget/Order'
import Profile_get from '../Profile_get'

import { ShoppingCartOutlined, SnippetsTwoTone, SettingOutlined, DashboardOutlined, DollarOutlined } from '@ant-design/icons';
import { AbcOutlined, AvTimerSharp, DoorbellSharp, GifBoxOutlined, InfoOutlined, MessageOutlined, Money, MoneyOffCsredOutlined, NotificationAdd, SupervisedUserCircleRounded, TwoK } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';


import Api from '../Url/nodeApi'
import Memo from './Coustomer_order_allget/Memo';





const drawerWidth = 240;





export default function Order_Confrim_view() {

  const {unidcode}=useParams();

// useEffect(() => {
//   All_user();
// }, [])
// const All_user=()=>{
//   let id=unidcode;
//   axios.get(`https://api.my-shopings.com/get_user_data/${id}`)
//   .then(res =>{
//     console.log('all ueser ====================================');
//     console.log(res.data);
//     console.log('====================================');
//   }).catch(error =>{
//     console.log('====================================');
//     console.log(error,'error alllllllllllllllll');
//     console.log('====================================');
//   })
// }







const [dash, sethomeDa] = useState('1')

useEffect(() => {

  Getproduct();



}, [])

const [orderall, setAorderData] = useState([])

const Getproduct=()=>{
let id=unidcode;

axios.get(`https://api.my-shopings.com/get_users_orders/${id}`)

.then(res=>{
  console.log(' order !!!!!!!!!!!!!!!!!!!!!!!====================================');
  setAorderData(res.data);
  console.log('====================================');
})
.catch(error =>{
  console.log('====================================');
  console.log('api order optin',error);
  console.log('====================================');
})


}




const gethomePage=()=>{







  switch (dash) {
    case '1':return <h3>1</h3>;
    case '2':return <Profile_get data={unidcode} />;
    case '3':return <h3>3</h3>;
    case '4':return <h3>4</h3>;
    case '5':return <Orderall  data={unidcode}  />;
    case '6':return <h3>6</h3>;
    case '7':return <h3>7</h3>;
    case '8':return <Memo /> ;
    case '19':return <h3>9</h3>;
    case '9':return <h3>10</h3>;
    

 
  
    default: return <All_chettiong  data={unidcode} />;
      
  }
}




  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            My-shopings.com
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
      className='bg-dark'
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box',  },
        }}
      >
        <Toolbar />
        <Box className='bg-dark text-white' sx={{ overflow: 'auto', }}>
          <List>
            {/* {['order', 'Balance', 'Notification', ''].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))} */}

<ListItem onClick={()=>sethomeDa('1')} className='btn  text-white' key={1} disablePadding>
                <ListItemButton>
                 

                  
                  <DashboardOutlined style={{ fontSize: '25px', color: '#08c', marginLeft:7, }} theme="outlined"  />
                  
                  <ListItemText className='' primary={'Dashbord'} /> {'  >'}
                </ListItemButton>
              </ListItem>






              <ListItem onClick={()=>sethomeDa('2')} className='btn  text-white' key={1} disablePadding>
                <ListItemButton>
                 

                  
                  <Avatar style={{ color: '#08c', marginLeft:7, fontSize:45 }}  size='9' />
                  
                  <ListItemText className='' primary={'Profile'} /> {'  >'}
                </ListItemButton>
              </ListItem>






              <ListItem onClick={()=>sethomeDa('3')} className='btn  text-white' key={1} disablePadding>
                <ListItemButton>
                 

                  
                  <NotificationAdd style={{ fontSize: '25px', color: '#08c', marginLeft:7, }} theme="outlined"  />
                  
                  <ListItemText className='' primary={'Notification'} /> {'  >'}
                </ListItemButton>
              </ListItem>



              <ListItem onClick={()=>sethomeDa('4')} className='btn  text-white' key={1} disablePadding>
                <ListItemButton>
                 

                  
                  <MessageOutlined style={{ fontSize: '25px', color: '#08c', marginLeft:7, }} theme="outlined"  />
                  
                  <ListItemText className='' primary={'Messenger'} /> {'  >'}
                </ListItemButton>
              </ListItem>

<ListItem onClick={()=>sethomeDa('5')} className='btn  text-white' key={1} disablePadding>
                <ListItemButton>
                 

                  
                  <ShoppingCartOutlined style={{ fontSize: '25px', color: '#08c', marginLeft:7, }} theme="outlined"  />
                  
                  <ListItemText className='' primary={'Orders'} /> {'  >'}
                </ListItemButton>
              </ListItem>


              <ListItem onClick={()=>sethomeDa('6')} className='btn  text-white' key={1} disablePadding>
                <ListItemButton>
                 

                  
                  <GifBoxOutlined style={{ color: '#08c', marginLeft:7, fontSize:'25px' }}  size='9' />
                  
                  <ListItemText className='' primary={'winner'} /> {'  >'}
                </ListItemButton>
              </ListItem>



              <ListItem onClick={()=>sethomeDa('7')} className='btn  text-white' key={1} disablePadding>
                <ListItemButton>
                 

                  <DollarOutlined style={{ fontSize: '25px', color: '#08c', marginLeft:7, }} theme="outlined"  />
                  
                  <ListItemText className='' primary={'Balance'}  /> {'  >'}
                </ListItemButton>
              </ListItem>


          
              <ListItem onClick={()=>sethomeDa('8')} className='btn  text-white' key={1} disablePadding>
                <ListItemButton>
                 

                  <SnippetsTwoTone style={{ fontSize: '25px', color: '#08c', marginLeft:7, }} theme="outlined"  />
                  
                  <ListItemText className='' primary={'Memo'}  /> {'  >'}
                </ListItemButton>
              </ListItem>





<ListItem onClick={()=>sethomeDa('195')} className='btn  text-white' key={1} disablePadding>
                <ListItemButton>
                 
              
                  <SettingOutlined spin  style={{ fontSize: '25px', color: '#08c', marginLeft:7, }} theme="outlined"  />
                  
                  <ListItemText className='' primary={'Setting'}  /> {'  >'}
                </ListItemButton>
              </ListItem>






              <ListItem onClick={()=>sethomeDa('9')} className='btn  text-white' key={1} disablePadding>
                <ListItemButton>
                 

                  
                  <InfoOutlined style={{ color: '#08c', marginLeft:7, fontSize:'25px' }}  size='9' />
                  
                  <ListItemText className='' primary={'About us'} /> {'  >'}
                </ListItemButton>
              </ListItem>




          </List>
          <Divider />
          {/* <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List> */}
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
       


{gethomePage()}






      </Box>
    </Box>
  );
}