import React, { useState,useEffect } from 'react';
import { PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal,Spin, Upload } from 'antd';


import { ToastContainer, toast } from 'react-toastify';


import axios from 'axios';








const getBase64 = (file) =>











  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });





  const { confirm } = Modal;


function Product_upload() {


  const [loadings, setLoadings] = useState([]);











  

  const notification=()=> toast.success('Upload successfully');


  const notification1=()=> toast.error('Upload successfully Error', {
    position: toast.POSITION.TOP_CENTER
  });
  
const [catagorr, setdatallcatGory] = useState([])

console.log(catagorr);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-2',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-3',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-4',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-xxx',
    //   percent: 50,
    //   name: 'image.png',
    //   status: 'uploading',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-5',
    //   name: 'image.png',
    //   status: 'error',
    // },
  ]);




  // const [selectedFiles, setSelectedFiles] = useState(null);

  // const handleFileChange = (event) => {
  //   setSelectedFiles(event.target.files);
  // };

  const [datali, setData] = useState([])

  // const formData = new FormData();

  // // Append each selected file to the FormData object
  // for (let i = 0; i < fileList.length; i++) {
  //   formData.append('files[]', fileList[i]);
  
  // }

  const[catagory, setCaagory]=useState('');

  const[old_new, setType_product]=useState('');

  const[hide_show, setType_hide]=useState('');

  const[model, setType_Model]=useState('');

  const[brand_name, setType_brand_name]=useState('');

  const[group, setType_group]=useState('');

  const[name, setType_Name]=useState('');

  const[company_profit_tk, setType_company_profit_taka]=useState('');

  const[profit_users_tk, setType_offer_price]=useState('');

  const[old_price, setType_old_price]=useState('');

  const[price, setType_price]=useState('');

  const[tag, setType_product_tag]=useState('');

  const[Disctription, setType_product_discription]=useState('');
  
  const[store_name, setType_product_shop_name]=useState('');





  const[store_color, setType_color]=useState('');
  const[store_color1, setType_color1]=useState('');
  const[store_color11, setType_color11]=useState('');

  

  const[size_pro, setType_size]=useState('');
  const[size_pro1, setType_size1]=useState('');
  const[size_pro2, setType_size2]=useState('');
  const[size_pro3, setType_size3]=useState('');
  const[size_pro4, setType_size4]=useState('');
  const[size_pro5, setType_size5]=useState('');
  // const[size_pro2, setType_size2]=useState('');
 

  const [stock, setTStock]=useState('');

  const id=localStorage.getItem('id');


	// const handderinputdata=(e)=>{
	// 	e.persist();
	// 	setAlldata({...alldataset, [e.target.name]: e.target.value});
	// }


const [imglink, setType_product_Link] = useState('')

    const [selectedFiles, setSelectedFiles] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };


  const formData = new FormData();
 

const [logintrue, setLodign] = useState(false)

  const handleUpload =  () => {

    setLodign(true);
//     confirm({
//       title: 'Upload Prossesing',
//       icon: <ExclamationCircleFilled />,
//       content: 
//       <Spin tip="Loading" size="large">
//           <div className="content" />
//           {Logindi}
//         </Spin>
// ,  
   
   
//       onCancel() {
//         console.log('onCancel');
//       },
//     });




   
if(selectedFiles){


    for (const file of selectedFiles) {
      formData.append('files[]', file);
    }

  }
    formData.append('catagory', catagory);
    formData.append('name', name);
    formData.append('model', model);
    formData.append('bran_name', brand_name);
    formData.append('old_new', old_new);
    formData.append('hide_show', hide_show);
    formData.append('company_profit', company_profit_tk);
    formData.append('oldprice', old_price);
    formData.append('users_tk', profit_users_tk);
    formData.append('tag', tag);
    formData.append('datail', Disctription);
    formData.append('price', price);
    formData.append('group', group);
    formData.append('store_name', store_name);


    

    formData.append('color1', store_color);
    formData.append('color', store_color1);
    formData.append('color11', store_color11);



    formData.append('stock', stock);





    formData.append('size_pro', size_pro);
    formData.append('size_pro1', size_pro1);
    formData.append('size_pro2', size_pro2);
    formData.append('size_pro3', size_pro3);
    formData.append('size_pro4', size_pro4);
    formData.append('size_pro5', size_pro5);



    formData.append('id', id);
  




    
    formData.append('linkimg', imglink);
     
  

      axios.post('https://court.my-shopings.com/public/api/upload_products',formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res=>{
        console.log(res.data.message);
        
        
        
        setTimeout(() => {
          notification();
          setLodign(false);
        }, 4000);
       


    
      }).catch(error => {
       
        // Handle error
        // setError(error);

        setTimeout(() => {
          notification1();
          setLodign(false);
        }, 4000);
        setLodign('upload error')
        console.log(error);
      });
  }














useEffect(() => {
  getidata();
}, [])



const getidata=()=>{
   
try {

  axios.get('https://court.my-shopings.com/public/api/catagory')
  .then(res=>{
   
    if(res.data.data){



      setdatallcatGory(res.data.data);

    }else{
      setdatallcatGory([])
    }


  })
  
} catch (error) {
  alert('eror ');
}finally{
 

  
}
}









  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);



    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );


console.log('====================================');
console.log(fileList);
console.log('====================================');

  return (
    <div className='container bg-dark rounded 'style={{boxShadow:'5px 2px red'}}>
    <div className='row'> 


    <div class="form-group card p-3 col-4 bg-dark m-4  " style={{boxShadow:'5px 2px red'}}>

<select class="form-select mt-3"  onChange={e=>setCaagory(e.target.value)}>
  <option>Select Catagory</option>
{catagorr.map(item =>
<>
{
  item.catagory ?
<option value={item.catagory}>{item.catagory}</option>
  :
  null
}


</>



)

}



</select>

</div>




<div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>

<select class="form-select mt-3" onChange={e=>setType_product(e.target.value)}>
<option>Select Action</option>
<option value={'old'}>Old</option>
<option value={'new'} defaultChecked>New</option>
<option className='default'>default</option>
</select>

</div>




<div class="form-group card p-3 col-2 bg-dark m-4" style={{boxShadow:'5px 2px red'}}> 

<select class="form-select mt-3" onChange={e=>setType_hide(e.target.value)}>
<option>hide/show</option>
<option value={'show'} defaultChecked>show</option>
<option value={'hide'}>hide</option>

</select>

</div>




   <div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>

   <select class="form-select mt-3" onChange={e=>setType_group(e.target.value)}>
     <option>Select group</option>
     {catagorr.map(item =>
<>
{
  item.groupp?
<option value={item.groupp}>{item.groupp}</option>
  :
  null
}


</>



)

}

   </select>

  </div>



  
  <div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>

<input type='text'  name='name'  onChange={e=>setType_Name(e.target.value)}  className='form-control' placeholder='product name'  />

</div>




<div class="form-group card p-3 col-2 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>

<input type='text'  name='model' onChange={e=>setType_Model(e.target.value)}  className='form-control' placeholder='product model'  />

</div>



<div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>



<select class="form-select mt-3"  onChange={e=>setType_brand_name(e.target.value)}>
  <option>Select Brand </option>
{catagorr.map(item =>
<>
{
  item.brand ?
<option value={item.brand}>{item.brand}</option>
  :
  null
}


</>



)

}



</select>



{/* <input type='text' name='brandname' onChange={e=>setType_brand_name(e.target.value)} className='form-control' placeholder='Product brand-name'   /> */}

</div>

<div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>

<input type='number' name='price' onChange={e=>setType_price(e.target.value)} className='form-control' placeholder='Product current price'   />

</div>



<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>

<input type='text' className='form-control' onChange={e=>setType_old_price(e.target.value)} name='removeprice' placeholder='Product de_price '   />

</div>



<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>

<input type='color' className='form-control' onChange={e=>setType_color(e.target.value)} name='removeprice' placeholder='color'   />

</div>

<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>

<input type='color' className='form-control' onChange={e=>setType_color1(e.target.value)} name='removeprice' placeholder='color-one'   />

</div>



<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>

<input type='color' className='form-control' onChange={e=>setType_color11(e.target.value)} name='removeprice' placeholder='color-two'   />

</div>


<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>

  

<input type='number' className='form-control' onChange={e=>setTStock(e.target.value)} name='stock' placeholder='product stock value'   />

</div>















{/* <label for="browser">Choose your browser from the list:</label>
  <input list="browsers" name="browser" id="browser">
  <datalist id="browsers">
    <option value="Edge">
    <option value="Firefox">
    <option value="Chrome">
    <option value="Opera">
    <option value="Safari">
  </datalist> */}

<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>


 
<input type='text'   list="browsers"  id="browser"  className='form-control' onChange={e=>setType_size(e.target.value)} name='removeprice' placeholder=' (xs) small size 38" '   />


<datalist id="browsers">
    <option value="xs" />
  
  </datalist>
</div>






<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>

<input type='text' className='form-control' onChange={e=>setType_size5(e.target.value)} name='removeprice' placeholder='(s)  size'   />

<datalist id="browsers-s">
    <option value="s" />
  
  </datalist>
</div>






<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>

<input type='text' className='form-control'  id="browsers-m" onChange={e=>setType_size1(e.target.value)} name='removeprice' placeholder='(m) midium size '   />


<datalist id="browsers-m">
    <option value="m" />
  
  </datalist>
</div>



<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>

<input type='text' id="browsers-l" className='form-control' onChange={e=>setType_size2(e.target.value)} name='removeprice' placeholder='(L) Larger size'   />


<datalist id="browsers-l">
    <option value="L" />
  
  </datalist>
</div>




<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>

<input type='text' id="browsers-xl" className='form-control' onChange={e=>setType_size3(e.target.value)} name='removeprice' placeholder='(xL) Larger size'   />


<datalist id="browsers-xl">
    <option value="XL" />
  
  </datalist>

</div>





<div class="form-group card p-3 col-2 bg-dark m-4"  style={{boxShadow:'5px 2px red'}}>

<input type='text' id="browsers-xxl" className='form-control' onChange={e=>setType_size4(e.target.value)} name='removeprice' placeholder='(xxL) Larger size'   />


<datalist id="browsers-xxl">
    <option value="XXL" />
  
  </datalist>

</div>















<div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>

<input type='number' name='offer_price' onChange={e=>setType_offer_price(e.target.value)} className='form-control' placeholder='Product offer price'   />

</div>

<div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>

<input type='number' name='company_profit_taka' onChange={e=>setType_company_profit_taka(e.target.value)} className='form-control' placeholder='Product profit price'   />

</div>







<div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>

<input type='name' name='tag' onChange={e=>setType_product_tag(e.target.value)} className='form-control' placeholder='Product tag name'   />

</div>





<div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>

<input type='name' onChange={e=>setType_product_shop_name(e.target.value)} name='company_profit_taka' className='form-control' placeholder='Product shop name '   />

</div>

<hr/>

<div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>

<textarea role='5' className='form-control'  onChange={e=>setType_product_discription(e.target.value)}  placeholder='Disctription product datails' rows={5} />

</div>




<div class="form-group card p-3 col-4 bg-dark m-4" style={{boxShadow:'5px 2px red'}}>




<input type="file" multiple onChange={handleFileChange} />


{/* 
<Upload
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal> */}




</div>



<div className='imgupload'>

 
<input type='link' className='form-control' placeholder=' link img product http://'  onChange={e=>setType_product_Link(e.target.value)}  />





</div>





    </div>













    <Button type="primary" className='btn btn-info p-2 m-2 col-md-6' onClick={()=>handleUpload(0)}   loading={logintrue}>
      
      Upload-product</Button>

      

    <button type="submit" class="btn bg-danger m-4 col-4">reset</button>






    </div>
  )
}

export default Product_upload
