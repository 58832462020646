import React,{useEffect,useState} from 'react';
import Avatar from '@mui/joy/Avatar';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Sheet, { sheetClasses } from '@mui/joy/Sheet';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRightRounded';
import Flight from '@mui/icons-material/Flight';
import Wifi from '@mui/icons-material/Wifi';
import Bluetooth from '@mui/icons-material/Bluetooth';
import Podcasts from '@mui/icons-material/Podcasts';
import { Switch } from 'antd';













import Urlnodej from '../../Url/nodeApi'







export default function Setting({data}) {






const [block, setBlock] = useState()

  
useEffect(() => {

  setInterval(() => {
    
  
  uergetDataall();

}, 3000);
}, [])


  const [uerdatain, setget_user] = useState([])

  const uergetDataall=()=>{
    let id=data;
      
    Urlnodej.get(`get_user_data/${id}`)
    .then(res =>{


      setget_user(res.data[0])

      if(res.data[0].status=='block'){

        setBlock(true);

      }else{

        setBlock(false)
      }



      console.log(res.data);
      
      console.log('get profile data all order ');
    })
    .catch(error =>{
      console.log(' get order li ====================================');
      console.log(error);
      console.log('====================================');
    })
    
  
  }






  // get_user_data



  const changer =(id)=>{
console.log('444444444====================================');
console.log(block);
console.log('====================================');



  }


  const onChange = (checked) => {
   if(checked==true){
 setBlock(true);
    blockk();

   }else{

    Unblockk();
    setBlock(false);
   }
  };

const Unblockk =() =>{
  let id=data;
  let vall='unblock';
      
    Urlnodej.put(`put_block_unblock/${id}/${vall}`)
    .then(res =>{
    
      console.log(res.data);
      
      console.log('block ok ');
    })
    .catch(error =>{
      console.log(' error block ====================================');
      console.log(error);
      console.log('====================================');
    })
  

}

const blockk =() =>{

  let id=data;
  let vall='block';
      
    Urlnodej.put(`put_block_unblock/${id}/${vall}`)
    .then(res =>{
      
      console.log(res.data);

      

      console.log('block ok ');
    })
    .catch(error =>{
      console.log(' error block ====================================');
      console.log(error);
      console.log('====================================');
    })
  
}

// useEffect(() => {
//   blockunblockget();
// }, [])



// const blockunblockget=() =>{

//   let blocki=uerdatain.status;
//   if(blocki=='block'){
//     setBlock(true)
//   }else{
//     setBlock(false);
//   }


// }


  return (

<div className='container'>


 



    <Sheet variant="soft" sx={{ width: 343, p: 2, borderRadius: 'sm' }}>
      <Typography
        level="h3"
        fontSize="xl2"
        fontWeight="xl"
        id="ios-example-demo"
        mb={1}
      >
        Settings
      </Typography>
      <List
        aria-labelledby="ios-example-demo"
        sx={(theme) => ({
          '& ul': {
            '--List-gap': '0px',
            bgcolor: 'background.surface',
            '& > li:first-child > [role="button"]': {
              borderTopRightRadius: 'var(--List-radius)',
              borderTopLeftRadius: 'var(--List-radius)',
            },
            '& > li:last-child > [role="button"]': {
              borderBottomRightRadius: 'var(--List-radius)',
              borderBottomLeftRadius: 'var(--List-radius)',
            },
          },
          '--List-radius': '8px',
          '--List-gap': '1rem',
          '--ListDivider-gap': '0px',
          '--ListItem-paddingY': '0.5rem',
          // override global variant tokens
          '--joy-palette-neutral-plainHoverBg': 'rgba(0 0 0 / 0.08)',
          '--joy-palette-neutral-plainActiveBg': 'rgba(0 0 0 / 0.12)',
          [theme.getColorSchemeSelector('light')]: {
            '--joy-palette-divider': 'rgba(0 0 0 / 0.08)',
          },
          [theme.getColorSchemeSelector('dark')]: {
            '--joy-palette-neutral-plainHoverBg': 'rgba(255 255 255 / 0.1)',
            '--joy-palette-neutral-plainActiveBg': 'rgba(255 255 255 / 0.16)',
          },
        })}
      >
        <ListItem nested>
          <List
            aria-label="Personal info"
            sx={{ '--ListItemDecorator-size': '72px' }}
          >
            <ListItem>
              <ListItemDecorator>
                <Avatar size="lg" sx={{ '--Avatar-size': '60px' }}>
             {data}
                </Avatar>
              </ListItemDecorator>
              <div>
                <Typography fontSize="xl">

                  {uerdatain.name}
                </Typography>
                <Typography fontSize="xs">
                

                {uerdatain.address}

                </Typography>
              </div>
            </ListItem>
            {/* <ListDivider inset="startContent" /> */}
            {/* <ListItem> */}
              {/* <ListItemButton>
                {/* <ListItemContent>iCloud+ Feature Updates</ListItemContent> */}
                {/* <KeyboardArrowRight fontSize="xl3" /> */}
              {/* </ListItemButton> */} 
            {/* </ListItem> */}
          </List>
        </ListItem>
        <ListItem nested>
          <ListItem
            sx={{
              bgcolor: 'background.surface',
              mb: 1,
              borderRadius: 'var(--List-radius)',
            }}
          >
            <ListItemButton
              aria-describedby="apple-tv-description"
              sx={{ borderRadius: 'var(--List-radius)' }}
            >
             
             {uerdatain.phone}
            </ListItemButton>
          </ListItem>
          <Typography id="apple-tv-description" level="body-xs" aria-hidden>
          
          {uerdatain.status}


          </Typography>
        </ListItem>
        <ListItem nested>
          <List
            aria-label="Network"
            sx={{
              [`& .${sheetClasses.root}`]: {
                p: 0.5,
                lineHeight: 0,
                borderRadius: 'sm',
              },
            }}
          >
            <ListItem>
              <ListItemDecorator>
                <Sheet variant="solid" color="warning">
                  <Flight />
                </Sheet>
              </ListItemDecorator>
              <ListItemContent htmlFor="airplane-mode" component="label">
                
              {!uerdatain.status ?
          
          <p className='badge h1 bg-info'>   Unblock</p>
          :
          
          <p className='badge h1 bg-danger'>Block</p>
          }



              </ListItemContent>



              <Switch checkedChildren="block" onChange={onChange}  checked={block}  unCheckedChildren="unblock"   />




            </ListItem>
            <ListDivider inset="startContent" />
            <ListItem>
              <ListItemButton>
                <ListItemDecorator>
                  <Sheet variant="solid" color="primary">
                    <Wifi />
                  </Sheet>
                </ListItemDecorator>
                <ListItemContent>Wi-Fi</ListItemContent>
                <Typography
                  textColor="text.tertiary"
                  sx={{ mr: 'calc(-1 * var(--ListItem-gap))' }}
                >
                  Mars
                </Typography>
                <KeyboardArrowRight fontSize="xl3" />
              </ListItemButton>
            </ListItem>
            <ListDivider inset="startContent" />
            <ListItem>
              <ListItemButton>
                <ListItemDecorator>
                  <Sheet variant="solid" color="primary">
                    <Bluetooth />
                  </Sheet>
                </ListItemDecorator>
                <ListItemContent>Bluetooth</ListItemContent>
                <Typography
                  textColor="text.tertiary"
                  sx={{ mr: 'calc(-1 * var(--ListItem-gap))' }}
                >
                  On
                </Typography>
                <KeyboardArrowRight fontSize="xl3" />
              </ListItemButton>
            </ListItem>
            <ListDivider inset="startContent" />
            <ListItem>
              <ListItemButton>
                <ListItemDecorator>
                  <Sheet variant="solid" color="success">
                    <Podcasts />
                  </Sheet> 
                </ListItemDecorator>
                <ListItemContent>Cellular</ListItemContent>
                <KeyboardArrowRight fontSize="xl3" />
              </ListItemButton>
            </ListItem>
          </List>
        </ListItem>
      </List>
    </Sheet>



    </div>
  );
}