import  React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import './Stylee.css'
import { Avatar, Button, CardActionArea, CardActions, Grid } from '@mui/material';
import { Divider, List, QRCode, Watermark } from 'antd';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 330,
  


  // backgroundImage: 'url("../../componet/logo/WhatsApp Image 2023-12-14 at 23.51.22_2b3f7549.jpg")',
  backgroundSize:'cover',
  // backgroundRepeat:'no-repeat',
  border: '8px solid blue',
  boxShadow: 50,
  color:'#fff',
  
  p: 4,
};
const ntne={background:'#fff'};

// let Urlim ='../logo/logo192.png';
// var kld='../logo/logo192.png


let Url='https://court.my-shopings.com/public/api/';
function Nidcard() { 
   const {unIdchek} =useParams();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    useEffect(() => {
    
      GetaIndcheck();

    }, [])


const [adminname, setAllAdminget] = useState('')


    const GetaIndcheck=()=>{
      axios.get(Url+`checknid_admin_add/${unIdchek}`)
      .then(res =>{
console.log(res.data);
setAllAdminget(res.data.message[0]);
      })
      .catch(error =>{
console.error('error catch get  getaInchekId',error);
      })
    }

  return (
    <div   className='bg-white'>
    
<Modal
  open={true}
  onClose={handleClose}
  aria-labelledby="modal-modal-title" 
  aria-describedby="modal-modal-description"
  className='bg-white'
>
 




  <Box sx={style} className={''} src={require('../../componet/logo/nidcardfont.jpg')} >

    <br/>
        <Grid fontSize={44} lg display="flex" justifyContent="center" alignItems="center">
        <Avatar
  alt="Remy Sharp"
  src={require('../../componet/logo/nidcardfont.jpg')}
  sx={{ width: 150, height: 150, }}
  style={{marginRight:78,marginTop:28,}}
/> 
</Grid>
 <Watermark
    height={10}
    width={100}
    image={require('../../componet/logo/logo192.png')}
  >




        <div className='p-3 ' style={{paddingLeft:9,background:'#460046', marginTop:9}}>



<h5 className=' card text-white' style={{paddingLeft:9,background:'#460046'}} >    {adminname.name} </h5>
<h6 className='text-white card'  style={{paddingLeft:9,background:'#460046'}} >{adminname.podobi}</h6>



<div className='' style={{paddingLeft:9,background:'#460046', position:'absolute', height:90, width:267, marginTop:30}}>
<p>
  
</p>
</div>




    
<li className='text-white card'  style={{paddingLeft:9,background:'#460046'}}>Id No : {adminname.uniqid} </li>
<li className='text-white card'  style={{paddingLeft:9,background:'#460046'}}>Blood : {adminname.blood} </li>
<li className='text-white card'  style={{paddingLeft:9,background:'#460046'}}>Email : {adminname.email} </li>
<li className='text-white card'  style={{paddingLeft:9,background:'#460046'}}>Phone : {adminname.phone}</li>









        </div>
      
        
      
    




    

      
   <div className='m-4' style={{marginTop:100,}}>


  
   <Grid fontSize={30}  
   
     lg display="flex"   justifyContent="center" alignItems="center">
      

      
   <QRCode
   size={110}
   bgColor='#fff'
   style={{
    marginBottom: 1,
    marginRight:70,
    padding:5,
    
  }}
errorLevel="H"
value="https://my-shopings.com/"

/> 


  </Grid>


  </div> 

  </Watermark>
  </Box>









</Modal>




    </div>
  )
}

export default Nidcard
