


import React,{useEffect,useState} from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';
import { Avatar } from '@mui/material';
// import HighlightedCode from 'docs/src/modules/components/HighlightedCode';

import './Stylee.css'
import { QRCode, Watermark } from 'antd';
import { useParams } from 'react-router-dom';
import axios from 'axios';

let Url='https://court.my-shopings.com/public/api/';
export default function Download_nid() {



 let Imgg=localStorage.getItem('img');
 
     const {unIdchek} =useParams();
      const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);
  
  
  
      useEffect(() => {
      
        GetaIndcheck();
  
      }, [])
  
  
  const [adminname, setAllAdminget] = useState('')
  
  
      const GetaIndcheck=()=>{
        axios.get(Url+`checknid_admin_add/${unIdchek}`)
        .then(res =>{
  console.log(res.data);
  setAllAdminget(res.data.message[0]);
        })
        .catch(error =>{
  console.error('error catch get  getaInchekId',error);
        })
      }











  const [spacing, setSpacing] = React.useState(2);

  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  }; 

  const jsx = `
<Grid container spacing={${spacing}}>
`;

  return (
    <Grid sx={{ flexGrow: 1 }} container bgcolor={'#fff'}  spacing={2}>
      <Grid  xs={12}>
        <Grid container justifyContent="center" spacing={3}>
         
            <Grid spacing={3} >
              <Paper
              className='Img'
                sx={{
                  margin:9,
                
                  height: 500,
                  width: 300,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#1A2027',
                }}>

<br/>


<Grid fontSize={40} lg display="flex" justifyContent="center" alignItems="center">
        <Avatar
  alt="my-shopings.com"
  // src={require('../../componet/logo/nidcardfont.jpg')}
  src={`https://court.my-shopings.com/public/admin_img/${adminname.img}`}
  sx={{ width: 118,  }}
  style={{marginRight:106,marginTop:48, backgroundrepeat:' no-repeat',
    backgroundattachment: 'fixed',
    backgroundsize: '100% 100%',
    
    height:'119px'
    }}
/> 
</Grid>








<Watermark
    height={10}
    width={100}
    image={require('../../componet/logo/logo192.png')}
  >




        <div className='p-3 ' style={{paddingLeft:9, marginTop:-17}}>

 


<p className=' text-white h5 b' style={{paddingLeft:9,}} >    {adminname.name} </p>

<p className='text-white h6'  style={{paddingLeft:9, fontSize:15,}} >{adminname.podobi}</p>







    
<li className='text-white btn'  style={{fontSize:13}}>Id No : {adminname.uniqid} </li>
<br/>
<li className='text-white btn'  style={{paddingLeft:9,fontSize:13}}>Blood : {adminname.blood} </li>
<br/>
<li className='text-white btn'  style={{paddingLeft:9,fontSize:13}}>Email : {adminname.email} </li>
<br/>
<li className='text-white btn'  style={{paddingLeft:9,fontSize:13}}>Phone : {adminname.phone}</li>









        </div>
      
        
      
    




    

      
   <div className='m-4' style={{marginTop:10,}}>


  
   <Grid fontSize={30}        lg display="flex"   justifyContent="center" alignItems="center">
      

      
   <QRCode
   size={100}
   bgColor='#fff'
   style={{
    marginTop:-30,
    marginRight:75,
    padding:5,
    
  }}
errorLevel="H"
value={`https://dashbord.my-shopings.com/Download-nid/${unIdchek}`}

/> 


  </Grid>


  </div> 

  </Watermark>






                </Paper>
             
            </Grid>
       
            <Grid spacing={3}>
              <Paper
              className='Imgck'
                sx={{
                  margin:9,
                  height: 500,
                  width: 300,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#1A2027',
                }}
>



<Grid fontSize={30}        lg display="flex"   justifyContent="center" alignItems="center">
      

      
      <QRCode
      size={110}
      bgColor='#fff'
      style={{
       marginBottom:-844,
       marginRight:5,
       padding:5,
       borderRadius:'40%',
       position:'absolute',
       
     }}
   errorLevel="H"
   value={`https://my-shopings.com`}
   
   /> 
   
   
     </Grid>

</Paper>


           
            </Grid>
       

        </Grid>
      </Grid>
      </Grid>
    
  );
}