import React,{useEffect,useState} from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';





import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {  ConfigProvider, Modal,  Breadcrumb, Input, Upload} from 'antd';

import Apinode from '../dashbord/Url/nodeApi'

import Laravelapi from '../dashbord/Url/Url'
// import {  useTheme } from 'antd-style';

import { useParams } from 'react-router-dom';

import Urlnodej from './Url/nodeApi'
import { Flag, VerifiedUserOutlined } from '@mui/icons-material';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';

//

export default function UserCard({data}) {
  const {unidcode}=useParams();


  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  





  const uploadfile=() =>{
    let id=localStorage.getItem('id');
    
    const formData = new FormData();


    formData.append('image', selectedFile);
    formData.append('id', id);


    Laravelapi.post('Profile-upload-admin',formData,{
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res =>{
      alert(res.data.data)
    })
.catch(error =>{
  alert('error upload', error)
})
  }

  

useEffect(() => {
  uergetDataall();
}, [])




  const [uerdatain, setget_user] = useState([])

  const [admin_nameAll, setNameadmin] = useState()

  
  const [admin_phone, setPHone] = useState()
  const [admin_account, setAccount] = useState()
  
  const [admin_img, setP_Img] = useState()
  
  const [admin_phone_addre, setPHo_add] = useState()
  const [admin_email, setP_Email] = useState()

  const [bloodd, setP_blood] = useState()



  const edit_admin={
    name:admin_nameAll,
    phone:admin_phone,
    email:admin_email,
    address:admin_phone_addre,
    blood:bloodd,
    id:localStorage.getItem('id'),
  }
  

  const Updateadmin=()=>{
    

    Apinode.put('admin_update',edit_admin)
    .then(res =>{
      alert(res.data)
    })
    .catch(error =>{
      console.log(error);
    })


  }


  const uergetDataall=()=>{
    let id=localStorage.getItem('id');
      
    Urlnodej.get(`get_user_data_admin/${id}`)
    .then(res =>{
      setget_user(res.data[0]);
      setNameadmin(res.data[0].name);
      setPHone(res.data[0].phone);
      setPHo_add(res.data[0].address);
      setP_Email(res.data[0].email);
      setAccount(res.data[0].uniqid);
      setP_Img(res.data[0].img);
  
      setP_blood(res.data[0].blood);
      console.log(res.data);
      
      console.log('get profile data all admin  ');
    })
    .catch(error =>{

      setget_user([]);
      console.log(' get order li ====================================');
      console.log(error);
      console.log('====================================');
    })
    
  
  }



  const [isModalOpen, setIsModalOpen] = useState(false);
 

 const toggleModal=()=>{
  setIsModalOpen(false)
 }

 
 const toggleModal_true=()=>{
  setIsModalOpen(true)
 }


  const modalStyles = {
    header: {
      borderLeft: `5px solid red`,
      borderRadius: 0,
      paddingInlineStart: 5,
    },
    body: {
      boxShadow: 'inset 0 0 5px #999',
      borderRadius: 5,
    },
    mask: {
      backdropFilter: 'blur(10px)',
    },
    footer: {
      borderTop: '1px solid #333',
    },
    content: {
      boxShadow: '0 0 30px #999',
    },
  };

























  // const [fileList, setFileList] = useState([
  //   {
  //     uid: '-1',
  //     name: 'image.png',
  //     status: 'done',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   },
  // ]);
  // const onChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  // };
  // const onPreview = async (file) => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   const imgWindow = window.open(src);
  //   imgWindow?.document.write(image.outerHTML);
  // };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: { xs: 'auto', sm: 'initial' },
      }}
    >




<Breadcrumb
    items={[
      {
        title: 'Home',
      },
      {
        title: 'Admin dashbord',
      },
      
      {
        title: 'Profile ',
      },
    ]}
  />


<React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        changer profile img
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>

        <input type="file" onChange={handleFileChange} />
     
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={uploadfile} autoFocus>
            upload 
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>



      <Box
        sx={{
          position: 'absolute',
          display: 'block',
          width: '1px',
          bgcolor: 'warning.300',
          left: '500px',
          top: '-24px',
          bottom: '-24px',
          '&::before': {
            top: '4px',
            content: '"edit"',
            display: 'block',
            position: 'absolute',
            right: '0.5rem',
            color: 'text.tertiary',
            fontSize: 'sm',
            fontWeight: 'lg',
          },
          '&::after': {
            top: '4px',
            content: '"profile"',
            display: 'block',
            position: 'absolute',
            left: '0.5rem',
            color: 'text.tertiary',
            fontSize: 'sm',
            fontWeight: 'lg',
          },
        }}
      />
      <Card
        orientation="horizontal"
        sx={{
          width: '100%',
          flexWrap: 'wrap',
          [`& > *`]: {
            '--stack-point': '500px',
            minWidth:
              'clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)',
          },
          // make the card resizable for demo
          overflow: 'auto',
          resize: 'horizontal',
        }}
      >
        <AspectRatio flex ratio="1" maxHeight={182} sx={{ minWidth: 182 }}>
          <img
            src={`https://court.my-shopings.com/public/admin_img/${admin_img}`}
            srcSet="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"
            loading="lazy"
            alt=""
          />

{/* <ImgCrop rotationSlider>
      <Upload
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
      >
        {fileList.length < 1 && '+ Upload'}
      </Upload>
    </ImgCrop> */}



        </AspectRatio>
        <CardContent>
          <Typography fontSize="xl" fontWeight="lg">
           name: {uerdatain.name}
          </Typography>

          <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
          phone: {uerdatain.phone}
         

          </Typography>
          <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
      
          account: {uerdatain.uniqid}

          </Typography>

          <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
          address: {uerdatain.address}
          
          </Typography>
          <Sheet
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              p: 1.5,
              my: 1.5,
              display: 'flex',
              gap: 2,
              '& > div': { flex: 1 },
            }}
          >
            <div>
              <Typography level="body-xs" fontWeight="lg">
                money widtro
              </Typography>
              <Typography fontWeight="lg">34</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg">
                Balance
              </Typography>
              <Typography fontWeight="lg">980</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg">
                Rating
              </Typography>
              <Typography fontWeight="lg">8.9</Typography>
            </div>
          </Sheet>
          <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
            <Button variant="outlined" color="neutral" onClick={toggleModal_true}>
              Edit
            </Button>
            <Button variant="solid" color="primary">
              Widtro money
            </Button>
          </Box>
        </CardContent>
      </Card>











      <ConfigProvider
        modal={{
          
          styles: modalStyles,
        }}
      >
        <Modal
          title="Admin profile Edit"
          open={isModalOpen}
          onOk={() => toggleModal('1')}
          onCancel={() => toggleModal('3')}
          footer="admin"
        >
        
         
         <Input size="large" placeholder=" admin name" value={admin_nameAll}  onChange={(e)=>setNameadmin(e.target.value)}  prefix={<VerifiedUserOutlined />} />
    <br />
 
    <Input size="large" placeholder="Phone nu6y" value={admin_phone}  onChange={(e)=>setPHone(e.target.value)}  prefix={<VerifiedUserOutlined />} />
    <br />
    <br />
    
    <Input size="large" placeholder="Email address" value={admin_email}  onChange={(e)=>setP_Email(e.target.value)}  prefix={<VerifiedUserOutlined />} />
    <br />
    <br />

    
    <Input size="large" placeholder="admin full address" value={admin_phone_addre}  onChange={(e)=>setPHo_add(e.target.value)}  prefix={<VerifiedUserOutlined />} />
    <br />
    <br />

    <Input size="large" placeholder="blood group a+" value={bloodd}  onChange={(e)=>setP_blood(e.target.value)}  prefix={<VerifiedUserOutlined />} />
    <br />
    <br />
         
         
         <Button className='btn container'  onClick={Updateadmin}>Update Profile</Button>
         
         
         
      
        </Modal>
      </ConfigProvider>
    






    </Box>
  );
}