import React from 'react'

function Product_return() {
  return (
    <div>
      return all
    </div>
  )
}

export default Product_return
