import React, { useState, useEffect } from 'react';

import { Column } from '@ant-design/plots';

import Urlnode from '.././Url/nodeApi'

const Clume = () => {


const [lenghtt, settTdata] = useState([])




useEffect(() => {
  // setInterval(() => {
  

  // }, 400);

  getorder();
}, [lenghtt])


const getorder=()  =>{

  Urlnode.get('products_order_pending')
  .then(res =>{
       settTdata(res.data.length);
console.log(' or ====================================');
console.log(res.data);
console.log('====================================');
  })
  .catch(error => {

    console.log('====================================');
    console.log(error);
    console.log('====================================');

  })



}





  const data = [
    {
      action: '浏览网站',
      pv: 50000,
    },
    {
      action: '放入购物车',
      pv: 35000,
    },
    {
      action: '生成订单',
      pv: 25000,
    },
    {
      action: '支付订单',
      pv: 15000,
    },
    {
      action: '完成交易',
      pv: 8500,
    },
  ];
  const config = {
    data,
    xField: 'action',
    yField: 'pv',
    conversionTag: {},
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  return (
    <>
   




  <h6 className='p-1 card bg-dark'  style={{color:'aqua'}}> order new  {lenghtt} </h6>
  <Column {...config} /> 

  </>
  
  
  )
};





export default Clume
