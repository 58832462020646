import React,{useEffect, useState} from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Link from '@mui/joy/Link';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import { Box, Divider, Grid } from '@mui/material';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useNavigate } from 'react-router-dom';



function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function InteractiveCard() {

 var najjVViate= useNavigate();
useEffect(() => {

  setInterval(() => {
     geAlldata();
  }, 3000);
   
}, [])

const [order, setOrder]=useState([])
const geAlldata=()=>{



axios.get('https://api.my-shopings.com/getall')
.then(res=>{
    console.log('all order folder all option get data all ====================================');
    setOrder(res.data);
   
    console.log(res.data,'====================================');
})
.catch(error =>{

  setOrder([]);
console.error(error);
})


}
  return (



    <>
    
      <Box sx={{ flexGrow: 1 }}>


      <div role="presentation"  className='text-white btn' onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" className='text-white btn' color="inherit" >
          Dashbord /
        </Link>
        <Link
          underline="hover"
          color="inherit"
          className='text-white btn'
          href="/material-ui/getting-started/installation/"
        >
          order item /
        </Link>
        <Link
          underline="hover"
          color="text.primary"
          
          aria-current="page"
          className='text-white btn'
        >
          new order item
        </Link>
      </Breadcrumbs>
    </div>

    <input type='name' placeholder='input name'  className='form-control col-md-4' />
<Divider />
<br/>





      <Grid
        container
        spacing={2}
        
    
      >

{/* 
<Grid
            key={name}
            xs={12}
            sm={6}
            md={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={180}
          >
           



{Object.keys(order).map(uniqid => (
          <div key={uniqid}>
            <h3>{uniqid}</h3>
            <ul>
              {order[uniqid].map(item => (
                <li key={item.id}>{item.name}</li>
                // Assuming 'id' and 'name' exist in your data structure
              ))}
            </ul>
          </div>
        ))}













          </Grid> */}







{/* 
{Object.keys(order).map(uniqid => (
          <div key={uniqid}>
            <h3>{uniqid}</h3>
            <ul>
              {order[uniqid].map(item => (
                <li key={item.id}>{item.name}</li>
                // Assuming 'id' and 'name' exist in your data structure
              ))}
            </ul>
          </div>
        ))} */}



          

{order ?



<>




{order.map(item =>(
 

<Card
      variant="outlined"
      orientation="horizontal"
      sx={{
        width: 320,
        margin:1,
        '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
      }}
    >
      <AspectRatio ratio="1" sx={{ width: 90 }}>
        <img
         src={`https://court.my-shopings.com/public/profile_users/${item.img}`}
         srcSet={`https://court.my-shopings.com/public/profile_users/${item.img}`}
          loading="lazy"
          alt=""
        />
      </AspectRatio>
      <CardContent>
        <Typography level="title-lg" id="card-description">
        {item.name}
        </Typography>
        <Typography level="body-sm" aria-describedby="card-description" mb={1}>
          <Link
            overlay
            underline="none"
            
            sx={{ color: 'text.tertiary' }}
          >
            {item.address}
          </Link>
        </Typography>
        <Chip
          
          color="primary"
          size="sm"
          sx={{ pointerEvents: '' }}
        >
         <Link href={`/Order_profile/${item.user_id}`}>View profile</Link>



        </Chip>
      </CardContent>
    </Card>
 
))}

    </>

:


null }

 

      </Grid>



      </Box>








    
    </>
   
  );
}