import { Card, Col, Row, Space,Avatar, List } from 'antd'
import React, { useEffect, useState } from 'react'
import Pei_plot from './Chart/Pei_plot';


import Urlnodej from './Url/nodeApi'


function User_list() {


  useEffect(() => {
    uergeUerAll();
  }, [])
  
  
  
  
  
  const [allUer, setget_Alll] = useState([])
  const [count, setget_lenght] = useState([])
  
  const uergeUerAll=()=>{
   
      
    Urlnodej.get(`get_user_data`)
    .then(res =>{
      setget_Alll(res.data)
    
      setget_lenght(res.data.lenght)
      console.log(res.data);
      
      console.log('get uer alll get 444444444444 ');
    })
    .catch(error =>{
  
      setget_Alll([]);
      console.log(' get order li ====================================');
      console.log(error);
      console.log('====================================');
    })
    
  
  }

  return (
    <>
    <div className='conainer'>

  
    <div className='bg-dark p-4 row'>

  
<div className='col-md-4'>

<table className='table card bg-dark shawdown-lg'>
<thead>





</thead>
<tbody>


{allUer ?

allUer.map((item,i)=>(





<tr className='m-1'>

<th>

  <Avatar  src={`https://court.my-shopings.com/public/profile_users/${item.img}`} >{item.name}</Avatar>
</th>

<th>{item.name}</th>

<th>{item.address}</th>



</tr>



))

:

null

}






</tbody>




</table>





  </div>
  <div className='col-md-4'>

fjkl44
  </div>
  <div className='col-md-4'>

fjkl44
  </div>


   



</div>
 </div>
   </>
  )
}

export default User_list