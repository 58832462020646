import { Card } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'

import Apinode from '../../dashbord/Url/nodeApi'






import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Home() {

  // accept_date
  // : 
  // null
  // action
  // : 
  // "pending"
  // address
  // : 
  // null
  // brand_name
  // : 
  // null
  // charg
  // : 
  // null
  // code
  // : 
  // "87464184"
  // id
  // : 
  // 11
  // offer
  // : 
  // null
  // oner_name
  // : 
  // null
  // phone
  // : 
  // null
  // product_name
  // : 
  // null
  // profit
  // : 
  // null
  // time_create
  // : 
  // "2024-01-31T18:00:00.000Z"
  // type_discript
  // : 
  // null
  // uname
  // : 
  // null
  // user_id
  // : 
  // "53"

  const update_data=()=> toast.success('update successfully');


  const deletedd=()=> toast.error('deleted successfully');
  
  const rejectdata=()=> toast.warning(' reject data ');



  const errorrr=()=> toast.error('servir site error ');

const [state_chan, setstate_Conch] = useState('');




    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

const [state_model, setstate_MOdel] = useState(false)





const [state_peding, setstate_data] = useState([])
useEffect(() => {
  
    getapipending();
 
}, [state_chan]);







const [state_pending, setstate_peding] = useState('')
const getapipending=()=>{
    Apinode.get('getApiproduct-service-delevery-pending')
    
    .then(res =>{
        console.log('pro--pending service product');
        console.log(res.data);
        setstate_peding(res.data.length);

        setstate_data(res.data);
    })
    .catch(error=>{

      setstate_peding(0);

        setstate_data([]);
    })
    
}

const oksubmite=(id)=>{
  
if(name=='deleted'){



  Apinode.post('getApiproduct-service-delevery-pending-deleted',{id:id})
    
  .then(res =>{
      console.log('pro--pending service product');
      console.log(res.data);
      deletedd();

    setstate_Conch(id);

    
  })
  .catch(error=>{

    errorrr();
  
  })





}else{
  







  Apinode.post('getApiproduct-service-delevery-pending-add-data',{name:name,id:id})
    
  .then(res =>{
      console.log('pro--pending service product');
      console.log(res.data);
      setstate_Conch('update');
  })
  .catch(error=>{

    errorrr();
  })




}




}


const [name, setName] = useState("");

const handleChange = (event) => {
  setName(event.target.value);
};













useEffect(() => {
 setInterval(() => {
  getapipending__waiting();


  getapipending__reject();
  getapipending_complete();
  getapipending_run_prosessin();
  getapipending_done_delive();



 }, 3000);
}, [])





const [state_pending_waiting, setstate_peding_waiting] = useState(0);
const [state__waiting, setstate_waiting] = useState([]);
const getapipending__waiting=()=>{
    Apinode.get('getApiproduct-service-delevery-waiting')
    
    .then(res =>{
        console.log('pro--pending service product');
        console.log(res.data);
        setstate_peding_waiting(res.data.length);

        setstate_waiting(res.data);
    })
    .catch(error=>{

      setstate_peding_waiting(0);

      setstate_waiting([]);
    })
    
}









const [state_pending_reject, setstate_peding_reject] = useState(0);
const [state_reject, setstate_reject] = useState([]);
const getapipending__reject=()=>{
    Apinode.get('getApiproduct-service-delevery-reject')
    
    .then(res =>{
        console.log('pro--pending service product');
        console.log(res.data);
        setstate_peding_reject(res.data.length);

        setstate_reject(res.data);
    })
    .catch(error=>{

      setstate_peding_reject(0);

      setstate_reject([]);
    })
    
}






const [state_pending_done_delive, setstate_done_delive] = useState(0);
const [state_done_delive, setstat_done_delivery] = useState([]);
const getapipending_done_delive=()=>{
    Apinode.get('getApiproduct-service-delevery-done-delive')
    
    .then(res =>{
        console.log('pro--pending service product');
        console.log(res.data);
        setstate_done_delive(res.data.length);

        setstat_done_delivery(res.data);
    })
    .catch(error=>{

      setstate_done_delive(0);

      setstat_done_delivery([]);
    })
    
}







const [state_pending_complete, setstate_peding_complete] = useState(0);
const [state_complete, setstate_complete] = useState([]);
const getapipending_complete=()=>{
    Apinode.get('getApiproduct-service-delevery-complete')
    
    .then(res =>{
        console.log('pro--pending service product');
        console.log(res.data);
        setstate_peding_complete(res.data.length);

        setstate_complete(res.data);
    })
    .catch(error=>{

      setstate_peding_complete(0);

      setstate_complete([]);
    })
    
}








const [state_pending_run_prosessing, setstate_pedirun_prosessin] = useState(0);
const [state_run_prosessin, setstate_run_prosessin] = useState([]);
const getapipending_run_prosessin=()=>{
    Apinode.get('getApiproduct-service-delevery-run-prosessing')
    
    .then(res =>{
        console.log('pro--pending service product');
        console.log(res.data);
        setstate_pedirun_prosessin(res.data.length);

        setstate_run_prosessin(res.data);
    })
    .catch(error=>{

      setstate_pedirun_prosessin(0);

      setstate_run_prosessin([]);
    })
    
}

  return (
    <div className='row'>
     <Card className='card col-3 bg-info mr-1 m-1' onClick={handleOpen}>


        <span className='p-3'>service-reaquest</span>{`( ${state_pending} )`}



     </Card>



     <Card className='card col-3 bg-info mr-1 m-1'>


<span className='p-3'>service-waiting</span>{`( ${state_pending_waiting} )`}



</Card>






<Card className='card col-3 bg-info mr-1 m-1'>


<span className='p-3'>service-run-prosessing</span>{`( ${state_pending_run_prosessing} )`}



</Card>






<Card className='card col-3 bg-info mr-1 m-1'>


<span className='p-3'>service-complete</span>{`( ${state_pending_complete} )`}




</Card>







<Card className='card col-3 bg-info mr-1 m-1'>


<span className='p-3'>service-delevery-done</span>{`( ${state_done_delive} )`}




</Card>





<Card className='card col-3 bg-info mr-1 m-1'>


<span className='p-3'>service-reject</span>{`( ${state_pending_reject} )`}



</Card>














<div>


     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <table className='table bg-dark'>
      <thead className='bg-dark'>
{state_peding.map((item, inde)=>(
   
   
        <tr>
          <th>{inde+1}</th>
          <th>{item.uname}</th>
          <th>{item.address}</th>
          <th>{item.phone}</th>
          <th>{item.product_name}</th>
          <th>{item.brand_name}</th>
          <th>{item.type_discript}</th>
         <th>
<select className='' onChange={handleChange}>
  <option value={'waiting'}>waiting</option>
  <option value={'run-prosessing'}>run-prosessing</option>
  <option value={'complete'}>complete</option>
  <option value={'done-delivery'}>done-delivery</option>
  <option value={'reject'}>reject</option>
  <option value={'deleted'}>deleted</option>
</select>








         </th>

         <th>

          <button  className='btn btn-danger' onClick={()=>oksubmite(item.id)}>submite</button>
         </th>
        </tr>
     
))}

</thead>
    </table>

          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
        </Box>
      </Modal>
    
</div>
    </div>
  )
}
