import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Home from './AllIndex/Home';
import Service from './AllIndex/Service';
import User_list from './AllIndex/User_list';
import Memo from './AllIndex/Memo';
import All_update from './AllIndex/All_update';
import About from './AllIndex/About';

const drawerWidth = 240;

export default function ClippedDrawer() {

const [numbercount, setNumber] = useState('1');


const getvlauehtmltemple=()=>{

switch (numbercount) {
  case '1':return <Home />
  case '2':return <Service />
  case '3':return <User_list />
  case '4':return <Memo />
  case '5':return <All_update />
  case '6':return <About />

  default: return <Home />
    
}

}


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" className='text-dark b' noWrap component="div">
           my-Service <sub className='smaill text-danger'>product</sub>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className='bg-dark '
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box className={'bg-info'} sx={{ overflow: 'auto' }}>
          <List>
           
              <ListItem disablePadding>
                <ListItemButton onClick={()=>setNumber('1')}>
                  <ListItemIcon>
                   <InboxIcon /> 
                  </ListItemIcon>
                  <ListItemText primary={'dashbord'} />
                </ListItemButton>
              </ListItem>






           
              <ListItem disablePadding>
              <ListItemButton onClick={()=>setNumber('2')}>
                  <ListItemIcon>
                   <InboxIcon /> 
                  </ListItemIcon>
                  <ListItemText primary={'service-request'} />
                </ListItemButton>
              </ListItem>





       
              <ListItem disablePadding>
              <ListItemButton onClick={()=>setNumber('3')}>
                  <ListItemIcon>
                   <InboxIcon /> 
                  </ListItemIcon>
                  <ListItemText primary={'top-service-list'} />
                </ListItemButton>
              </ListItem>



              <ListItem disablePadding>
              <ListItemButton onClick={()=>setNumber('5')}>
                  <ListItemIcon>
                   <InboxIcon /> 
                  </ListItemIcon>
                  <ListItemText primary={'update all'} />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
              <ListItemButton onClick={()=>setNumber('4')}>
                  <ListItemIcon>
                   <InboxIcon /> 
                  </ListItemIcon>
                  <ListItemText primary={'all memo'} />
                </ListItemButton>
              </ListItem>







              <ListItem disablePadding>
              <ListItemButton onClick={()=>setNumber('6')}>
                  <ListItemIcon>
                   <InboxIcon /> 
                  </ListItemIcon>
                  <ListItemText primary={'about app'} />
                </ListItemButton>
              </ListItem>




       
          </List>
          <Divider />
          


          
        </Box>
      </Drawer>
      <Box component="main" className='bg-dark' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />


        
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
{getvlauehtmltemple()}
        </Typography>
      </Box>
    </Box>
  );
}