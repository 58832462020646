import React,{useRef,useEffect, useState}from 'react'
import { Tabs, DatePicker, Button, Col, Row, Divider, Watermark } from 'antd';
import Urnodej from '../../Url/nodeApi'

import { useReactToPrint,ReactToPrint, PrintContextConsumer } from 'react-to-print';
import './Memo.css'; // Import your CSS file
import { Print } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
const dateFormat = 'YYYY/MM/DD';
const { TabPane } = Tabs;


export default function Memo() {

  const price = 143450;

  console.log(new Intl.NumberFormat('en-US').format(price)); // 143,450
  console.log(new Intl.NumberFormat('en-IN').format(price)); // 1,43,450
  console.log(new Intl.NumberFormat('en-DE').format(price)); // 143.450
  

  const [num, setNum] = useState(0);
 
    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };
 
  

    

  const {unidcode}=useParams();

useEffect(() => {

  setNum(randomNumberInRange(1, 20000000));
  uergetDataall();
}, [])




useEffect(() => {
  uerget_Orderl();
  uerget_Orderl_price();
}, [])




// get_users_orders_prices_memo
const [totaprice, setget_Ordr_totall_price]=useState(0);
const [Item, setget_Ordr_totall_Itemall]=useState(0);
const uerget_Orderl_price=()=>{ 
  let id=unidcode;
    
  Urnodej.get(`get_users_orders_prices_memo/${id}`)
  .then(res =>{
    setget_Ordr_totall_price(res.data[0])
    setget_Ordr_totall_Itemall(res.data[0]);
    console.log(
      '555555555555555555555555555'
    );
    console.log(res.data);
 
  })


  .catch(error =>{

    setget_Ordr_totall_price([])
    setget_Ordr_totall_Itemall([]);
    console.log(' get order li ====================================');
    console.log(error);
    console.log('====================================');
  })
  

}




const [orderlall, setget_Ordr] = useState([])
const [orderl_lenght, setget_Ordr_length] = useState([])

const uerget_Orderl=()=>{ 
  let id=unidcode;
    
  Urnodej.get(`get_users_orders_order_confilr_memo/${id}`)
  .then(res =>{
    setget_Ordr(res.data)
      setget_Ordr_length(res.data.length)
    console.log(res.data);
    
    console.log('get profile data all order          8777777777777777777  get_users_orders_order_confilr_memo ');
  })


  .catch(error =>{

    setget_user([]);
    console.log(' get order li ====================================');
    console.log(error);
    console.log('====================================');
  })
  

}







  const [uerdatain, setget_user] = useState([])

  const uergetDataall=()=>{
    let id=unidcode;
      
    Urnodej.get(`get_user_data/${id}`)
    .then(res =>{
      setget_user(res.data[0])
      console.log(res.data);
      
      console.log('get profile data all order ');
    })


    .catch(error =>{

      setget_user([]);
      console.log(' get order li ====================================');
      console.log(error);
      console.log('====================================');
    })
    
  
  }




  const memoprint = useRef();
  const handlePrint = useReactToPrint({
    content: () => memoprint.current,
 
  });























  return (
    <div className='container ' >
    <div className='row '>
    <div className='p-3  memo-list'>

<Tabs defaultActiveKey="2">
    <TabPane
      tab={
        <span>
          
        <input type='date' className='from-control' />




        </span>
      }
      key="1"
    >



<>


<div  className='bodyy'>

  <h3>my-shopings.com</h3>


<>





<Button onClick={handlePrint}>print</Button>



  

    <div className="memo-container card border memo-list" ref={memoprint}>
      <div className='p-4 memo-list'>


      <Watermark
    height={60}
    width={80}
    image="https://court.my-shopings.com/public/logo/logo_user.png"
  >
   
 




  <p>Memo number : {num}</p>
   

      <Row>





<div className='col-md-6 p-1 border-left'>
  
<p className='bttt'> Sender.......</p>
<p className='bttt'>  my-shopings.com</p>
<p className='bttt'>  my-shopings</p>
<p className='bttt'>  01795476575</p>


</div>


<div className='col-md-6 p-1'>

<tr>

<p className='bttt'> Recever.......</p>
<p className='bttt'>  Name : {uerdatain.name}</p>
<p className='bttt'>  phone : {uerdatain.phone}</p>
<p className='bttt'>  email : {uerdatain.email}</p>
<p className='bttt'>  address : {uerdatain.address}</p>



</tr>




</div>


<Divider className='bg-info p-1'/>

  <table className='table text-center'>
  <thead>
  <th>count </th>
  <th>id </th>
  <th>name </th>
  <th>type </th>
  <th>price </th>
  <th>quntity</th>
  <th>total price</th>
  
  </thead>
<tbody>


{orderlall ?

orderlall.map((item,i)=>(

<tr>

<td>{i+1}</td>
<td>{item.product_id}</td>
<td>{item.name}</td>
<td>{item.model}</td>
<td>{item.price}</td>
<td>{item.quntity}</td>
<td>

{new Intl.NumberFormat('en-IN').format(item.price  * item.quntity)}


</td>

</tr>


))

:


null


}

</tbody>
  </table>
  <Divider className='bg-dark' />
<table className='table'>



<tr>
<td>count : {orderl_lenght}</td>

<td>{' '}</td>

<td>{' '}</td>

<td>{' '}</td>
<td>{' '}</td>
<td>{' '}</td>




<td> Item count : {totaprice.product_count}</td>



<td>Total bill :{' '}{new Intl.NumberFormat('en-IN').format(totaprice.TOTAL_PRICE)} {' /-'}</td>
</tr>


</table>



  </Row>






  </Watermark>



    </div>
    </div>











</>





</div>





</>




    </TabPane>
    <TabPane
      tab={
        <span>
         
          Tab 2
        </span>
      }
      key="2"
    >
      Tab 2
    </TabPane>
  </Tabs>
      
    </div>
    </div>
    </div>
  )
}
