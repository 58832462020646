import React,{useEffect, useState} from 'react';
import { Carousel } from 'antd';
import { useParams } from 'react-router-dom';


import nodEapi from '../dashbord/Url/nodeApi'


const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};
function View_product() {
  const unidcode=useParams();
  useEffect(() => {
  
  Allgetall();
  }, [])

const [dataall, setDatall] = useState([])
  const Allgetall=()=>{
    nodEapi.get(`product_ge/${unidcode.id}`)
    .then(res =>{
      console.log(res.data);
      setDatall(res.data);
      console.log('====================================');
      console.log('roducty eeeeeeeeeeeeeeeeeerorrrrrrrrrrrr get catagori');
      console.log('====================================');
    }).catch(error =>{
      console.log(error);
    })
  }
  return (



  
  <Carousel effect="fade">
  {dataall.map((item,ind)=>{

<div>
  <h3>kf</h3>
</div>


  })}
  </Carousel>



  )
}

export default View_product