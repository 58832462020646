import * as React from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import Typography from '@mui/joy/Typography';


import Card from './Allorder/All_option'
export default function TabsPricingExample() {
  return (
    <Tabs
      variant="outlined"
      aria-label="Pricing plan"
      defaultValue={0}
      className='bg-dark text-white'
      sx={{
        width: '100%',
        borderRadius: 'lg',
        boxShadow: 'sm',
        overflow: 'auto',
      }}
    >
      <TabList
      bgcolor={'#000'}
        disableUnderline
        tabFlex={1}
        sx={{
          [`& .${tabClasses.root}`]: {
            fontSize: 'sm',
            fontWeight: 'lg',
            [`&[aria-selected="true"]`]: {
              color: 'primary.500',
              bgcolor: 'background.surface',
            },
            [`&.${tabClasses.focusVisible}`]: {
              outlineOffset: '-4px',
            },
          },
        }}
      >
        <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
          order new
        </Tab>
        <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
          order pendding
        </Tab>



        <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
          order accecpt  all
        </Tab>

        <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
          order reject all
        </Tab>
      </TabList>
      <TabPanel value={0}>
        

<Card  />





      </TabPanel>



      <TabPanel value={1}>
        <Typography level="inherit">
          Best for professional developers building enterprise or data-rich
          applications.
        </Typography>
        <Typography textColor="primary.400" fontSize="xl3" fontWeight="xl" mt={1}>
          $15{' '}
          <Typography fontSize="sm" textColor="text.secondary" fontWeight="md">
            / dev / month
          </Typography>
        </Typography>
      </TabPanel>



      <TabPanel value={2}>
        <Typography level="inherit">
          

<p>fjljalfkjmd fjlfj</p>

        </Typography>
        <Typography textColor="primary.400" fontSize="xl3" fontWeight="xl" mt={1}>
          <Typography
            fontSize="xl"
            borderRadius="sm"
            px={0.5}
            mr={0.5}
            sx={(theme) => ({
              ...theme.variants.soft.danger,
              color: 'danger.400',
              verticalAlign: 'text-top',
              textDecoration: 'line-through',
            })}
          >
            $49
          </Typography>
          $37*{' '}
          <Typography fontSize="sm" textColor="text.secondary" fontWeight="md">
            / dev / month
          </Typography>
        </Typography>
      </TabPanel>




      <TabPanel value={3}>
        <Typography level="inherit">
          The most advanced features for data-rich applications, as well as the
          highest priority for support.
        </Typography>
        <Typography textColor="primary.400" fontSize="xl3" fontWeight="xl" mt={1}>
          <Typography
            fontSize="xl"
            borderRadius="sm"
            px={0.5}
            mr={0.5}
            sx={(theme) => ({
              ...theme.variants.soft.danger,
              color: 'danger.400',
              verticalAlign: 'text-top',
              textDecoration: 'line-through',
            })}
          >






    
          </Typography>
         
          <Typography fontSize="sm" textColor="text.secondary" fontWeight="md">
            
<h4>fkljalfjlajf53535353</h4>


          </Typography>




        </Typography>
      </TabPanel>



    </Tabs>
  );
}