import React from 'react'
import Home from './componet/Home'
import Regis from './componet/Page/Regis'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Login from './componet/Page/Login'
import { useEffect } from 'react'

import Donload from './componet/Nidcard/Download_nid'

import { ToastContainer, toast } from 'react-toastify';
import Nidcard from './componet/Nidcard/Nidcard'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Order_Confrim_view from './componet/dashbord/Allorder/Order_Confrim_view'
import View_product from './componet/View_product/View_product'
import Index from './componet/Product_service/Index'
import Kisti_hisab_account from './componet/Kisti_hisab/Kisti_hisab_account'




export default function App() {





  return (
    <div>
      
<ToastContainer />


<Routes>
  <Route path='/Regg'  element={<Regis />} />



  <Route path='/'  element={<Login />} />





  <Route path='/home'  element={<Home />} />



  <Route path='/Order_profile/:unidcode'  element={<Order_Confrim_view />} />

  <Route path='/Download-nid/:unIdchek'  element={<Donload />} />



  <Route path='/Nid/:unIdchek'  element={<Nidcard />} />








  <Route path='/product-service'  element={<Index />} />






  <Route path='/saptahik_kisti'  element={<Kisti_hisab_account />} />















  <Route path='/Product_view/:id'  element={<View_product />} />



</Routes>

    </div>
  )
}
