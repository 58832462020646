import React, { useEffect } from 'react'
import '../../App.css'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';


import ApiLaravel from '../dashbord/Url/Url'

function Regis() {

	var nagivation=useNavigate();
const notification=()=> toast.success('Admin Registration successfully');


const notificatiErrro=()=> toast.error('Error Unsuccessfully');

const notificatiErrroFace=()=> toast.warning('alredy exit phone/email');

	
useEffect(() => {
 


}, [])



	const[namelekha,seTnametate]=useState('')
	const[email,setEmail]=useState('')
	const[phone,setPhone]=useState('')
	const[pass,seTpaaa]=useState('')
	const[address,setaddress]=useState('')
	const[file,setFilee]=useState('')
	const[blood,setBlood]=useState('')







	let axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			"Access-Control-Allow-Origin": "*",
		}
	  };

	  const customHeaders = {
		'Content-Type': 'application/json',
			"Access-Control-Allow-Origin": "*",
	
	};

	const formData = new FormData();

	






	const onlcikk=()=>{

// Example of a GET request


formData.append('name', namelekha)
	formData.append('phone', phone)
	formData.append('email', email)
	formData.append('pass', pass)
	formData.append('address', address)
	formData.append('blood', blood) 
	formData.append('img[]', file)


	

// axios.get('api/getdata')
// // .then(response => response.json())
// .then(re =>{
// 	console.log(re.data); }
// )

// .catch(error => {
//   console.error('error verver: ldkf');
// });



	ApiLaravel.post('addmine',formData,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
	.then(res => {
		
		if(res.data.message==100){
			notification();
			nagivation('/');
		}else if(res.data.message==103){
			notificatiErrroFace();
		}else{
			notificatiErrro();
		}
		
	}) .catch (error =>{
		notificatiErrro(error);
	})

	}
  return (
    <div className='container'>
      <div class="modal bg-dark"  style={{display:'block',}} tabindex="-1" role="dialog">
  <div class="modal-dialog bg-dark" role="document">
    <div class="modal-content bg-dark p-4 mt-5">
   
      


      <div class="container h-100 p-1 ">
		<div class="d-flex justify-content-center h-100">
			<div class="user_card">
				<div class="d-flex justify-content-center">
					<div class="brand_logo_container">
						<img src={require('../logo/logo192.png')} class="brand_logo" alt="Logo" />
					</div>
				</div>
				<div class="d-flex justify-content-center form_container">
					<form >


				


						<div class="input-group mb-3">
							<div class="input-group-append">
							
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="text" onChange={(e)=>seTnametate(e.target.value)} name="name" class="form-control input_user"  placeholder="username" />
						</div>

						<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="text" name="phone" class="form-control input_user" onChange={(e)=>setPhone(e.target.value)} placeholder="phone" />
						</div>


						<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="text" name="email" class="form-control input_user" onChange={(e)=>setEmail(e.target.value)} placeholder="email@gmail.com" />
						</div>



						<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="text" name="address" class="form-control input_user" onChange={(e)=>setaddress(e.target.value)} placeholder="address" />
						</div>




						<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="password" name="pass"  class="form-control input_pass" onChange={(e)=>seTpaaa(e.target.value)} placeholder="password new" />
						</div>



	<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="name" name="group"  class="form-control input_pass" onChange={(e)=>setBlood(e.target.value)} placeholder="Blood Group O+ A- A+.." />
						</div>


	<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="file" name="file" multiple  class="form-control input_pass" onChange={(e)=>setFilee(e.target.value)} placeholder="password new" />
						</div>

						{/* <div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fa fa-key"></i></span>
							</div>
							<input type="password" name="pass"  class="form-control input_pass" onChange={(e)=>seTpaaa(e.target.value)} placeholder="password new" />
						</div> */}




							<div class="d-flex justify-content-center mt-3 login_container">
				 	
				   </div>

				 
					</form>
				</div>
				<button type="button" onClick={onlcikk} name="button" class="btn login_btn">Register</button>
				<div class="mt-4">
					<div class="d-flex justify-content-center links">
						 have an account? <Link to={'/'}>Login</Link>
					</div>
					<div class="d-flex justify-content-center links">
						
					</div>
				</div>
			</div>
		</div>
	</div>






 
    </div>
  </div>
</div>
    </div>
  )
}

export default Regis
