

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';

import Urlnode from '.././Url/nodeApi'






export default function Clupoll() {


  const [lenghtt, settTdata] = useState([])

  const [data, setData] = useState([]);

  useEffect(() => {
    asyncFetch();
  }, [lenghtt]);

  // products_order_waiting

  const asyncFetch = () => {
    Urlnode.get('products_order_waiting')
  .then(res =>{
       settTdata(res.data.length);
console.log(' or ====================================');
console.log(res.data);
console.log('====================================');
  })
  .catch(error => {

    console.log('====================================');
    console.log(error);
    console.log('====================================');

  })

  };
  const config = {
    data,
    xField: '城市',
    yField: '销售额',
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    slider: {
      start: 0.1,
      end: 0.50,
    },
  };

  return(

    <>
   




    <h6 className='p-1 card bg-dark'  style={{color:'aqua'}}> order waiting  {lenghtt} </h6>
    <Column {...config} /> 
   b
    </>
  )
  
  
 
};

