
import axios from 'axios'
import React,{useEffect,useState}from 'react'



import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Button, Image, Modal, Space, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



function ProductUpdate() {


  const [brandname, setsTabrand] = useState('');

  const handleChange = (event) => {
    setsTabrand(event.target.value);
  };






  let Url='https://court.my-shopings.com/public/api';



const [alproduct, setDatall] = useState([]);


const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);






  const [name, setallUpdate] = useState();
  const [model, setallUModel] = useState();
  const [pid, setallPid] = useState();
  const [img, setImg] = useState();
  const [brand, setBrand] = useState();
  const [catagori, setCatagori] = useState();
  const [old, setOld] = useState();
  const [pricee, setPrice] = useState();
  const [reprice, setReprice] = useState();
  const [offertk, setOffer] = useState();
  const [tag, setTag] = useState();
  const [chopname, setchopname] = useState();
  const [ditall, setDitall] = useState();
  const [pag, setPag] = useState();
  const [createid, setUId] = useState();
  const [uniqid, setUniid] = useState();
  const [updtimedate, setallUpdateTime] = useState();
  const [profit, setProfit] = useState();

  let alldata={
name:name,
model:model,
id:pid,
brand:brand,
catagori:catagori,
old:old,
pricee:pricee,
reprice:reprice,
offertk:offertk,
profit:profit,
tag:tag,
chopname:chopname,
ditall:ditall,
img:img,
upid:localStorage.getItem('id') 


  };

//   'model' => $request['model'],
//   'name' => $request['name'],
//   'brand' => $request['brand'],
//    'catagori' => $request['catagori'],
//     'old' => $request['old'],
//      'pricee' => $request['pricee'],
//       'pricee' => $request['pricee'],
//        'reprice'	=> $request['reprice'],
// 'offerprice' => $request['offertk'],
// 'profit'	=> $request['pricee'],
// 'tag'=> $request['tag'],
// 'storename'	=> $request['chopname'],
// 'discript'	=> $request['ditall'],
// 'updateid'	=> $request['upid'],

 
  const showModal = (id) => {
    


    axios.get(Url+`/productdateid/${id}`)
    .then(res => {
      console.log(res.data.message);
     
      setallUpdate(res.data.message.name);
      setallUModel(res.data.message.model);
      setallPid(res.data.message.id);
      setBrand(res.data.message.brand);
      setCatagori(res.data.message.catagori);
      setOld(res.data.message.old);
     
      setPrice(res.data.message.pricee);
      setProfit(res.data.message.profit);
      setReprice(res.data.message.reprice);
      setOffer(res.data.message.offerprice);
      setTag(res.data.message.tag);

      if(res.data.message.img){
   
      setImg(res.data.message.img);
    }else{
      setImg(res.data.message.imglink);
    }


      setUId(res.data.message.createid);
      setUniid(res.data.message.uniqid);
      setDitall(res.data.message.discript);
      setPag(res.data.message.pag);
      setchopname(res.data.message.storename);
      setallUpdateTime(res.data.message.updateid);
    }).catch(error=>{
      console.log('error  thi a error :: ',error);
    })




    setOpen(true);
  };
  const handleOk = () => {

axios.post(Url+'/updatproduct',alldata)
.then(res => {
console.log(res.data);
})
.catch(errror =>{
alert(errror)

})





    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 5000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {


      Allgetall();
   
    
  
   
  }, [brandname])


useEffect(() => {

  

  Allgetalleg_catagory();
 
}, [])

const showModaldeled=(id,img)=>{
  
axios.delete(Url+`/product_delete/${id}/${img}`)
.then(res => {
console.log(res.data.message);
alert(res.data.message);
})
.catch(errror =>{
alert(errror)

})
}

const [branall, setDatCatagory] = useState([])
const Allgetalleg_catagory=()=>{
  axios.get(`https://api.my-shopings.com/product`)
  .then(res =>{
    console.log(res.data);
    // alert(res.data)
    setDatCatagory(res.data);
  }).catch(error =>{
    console.log(error);
  })
}


// const [brandname, setsTabrand] = useState()

const Allgetall=()=>{
  axios.get(`https://api.my-shopings.com/product_get/${brandname}`)
  .then(res =>{
    console.log(res.data);
    setDatall(res.data);
    console.log('====================================');
    console.log('roducty eeeeeeeeeeeeeeeeeerorrrrrrrrrrrr get catagori');
    console.log('====================================');
  }).catch(error =>{
    console.log(error);
  })
}

// const onchhadehide=(id)=>{
//   alert(id);
// }


const onchhadehide = (id,hide) => {
  


  axios.post(Url+'/hide_show_update',{id:id,hide:hide})
  .then(res =>{
   console.log(res.data);
  }).catch (error=> {
    alert(error)
  });






};
  return (
    <div className='container'>

<div className='col-8 m-3'>
<FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Brand name </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={brandname}
          label="brand name"
          onChange={handleChange}
        >
          {branall.map((item)=>

            <MenuItem value={item.catagori}>{item.catagori}</MenuItem>

          )}
         
        </Select>
      </FormControl>
  
</div>

<table className='bg-dark table text-white p-2'>
<tr className='p-2'>
  <th>count</th>
  <th>img</th>
  <th>name</th>
  <th>model</th>
  <th>price</th>
  <th>deled</th>
  <th>offer</th>

  <th>action</th>
</tr>

<tbody>

    {alproduct.map((item,id)=>(
    <tr>
      <td>{id+1}</td>
      <td> {item.img ?






<Image.PreviewGroup
items={[
  `https://court.my-shopings.com/public/uploads_product/${item.img}`
]}
>
<Image
  
  src={`https://court.my-shopings.com/public/uploads_product/${item.img}`} width={100}  height={100}
/>
</Image.PreviewGroup>





      // <img src={`https://court.my-shopings.com/public/uploads_product/${item.img}`} width={100}  height={100}  />
    :


    <Image.PreviewGroup
items={[
  item.imglink
]}
>
<Image
  
  src={item.imglink} width={100}  height={100}
/>
</Image.PreviewGroup>
   
    
    }     </td>
      <td>{item.name}</td>
      <td>{item.model}</td>

      <th>{item.pricee}</th>
      <th>{item.reprice}</th>
  <th>{item.offerprice}</th>



      <td>
        
{item.hide == 'hide' ?

<Switch    onChange={()=> onchhadehide(item.id,'show')} checkedChildren="show" unCheckedChildren="hide"  />
:

<Switch    onChange={()=> onchhadehide(item.id,'hide')} checkedChildren="show" unCheckedChildren="hide" defaultChecked />
}
      
        
        <button className='btn btn-info m-1' onClick={()=>showModal(item.id)}>edit</button>
      
        <button className='btn btn-info m-1' onClick={()=>showModaldeled(item.id,item.img)}>remove</button>
      
      
      </td>
    </tr>
    ))}

</tbody>

</table>



      <Modal
        open={open}
        title="Update Product"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            Submit
          </Button>,
      
        ]}
      >
   
<table className='table '>
 


<tbody>
  <tr>
    <td> product name :<input type='name'  value={name} onChange={e=>setallUpdate(e.target.value)} className='form-control' placeholder='name'   /> </td>

    <td> Model  <input type='name' value={model} onChange={e=>setallUModel(e.target.value)}  className='form-control' placeholder='model'   /> </td>

    <td> product Id<input type='name' value={pid} onChange={e=>setallPid(e.target.value)}   className='form-control' placeholder='product id'   /> </td>
  
  </tr>

  <tr>
    <td> price <input type='name'  value={pricee} onChange={e=>setPrice(e.target.value)} className='form-control' placeholder='price'   /> </td>

    <td> reprice <input type='name' value={reprice} onChange={e=>setReprice(e.target.value)}  className='form-control' placeholder='Reprice'   /> </td>

    <td> offer taka<input type='name' value={offertk} onChange={e=>setOffer(e.target.value)}   className='form-control' placeholder='Offer price'   /> </td>
  
  </tr>




  <tr>
    <td> company profit<input type='name'  value={profit} onChange={e=>setProfit(e.target.value)} className='form-control' placeholder='profit'   /> </td>

    <td> brand name <input type='name' value={brand} onChange={e=>setBrand(e.target.value)}  className='form-control' placeholder='brand'   /> </td>

    <td> catagory <input type='name' value={catagori} onChange={e=>setCatagori(e.target.value)}   className='form-control' placeholder='product catagori'   /> </td>
  
  </tr>



  <tr>
    <td> product tag all <input type='name'  value={tag} onChange={e=>setTag(e.target.value)} className='form-control' placeholder='tag all'   /> </td>

    <td>
      discription
    <TextArea value={ditall} onChange={e=>setDitall(e.target.value)}  className='form-control' placeholder='ditall'  />
  

  
    </td>
new/old
    <td> <input type='name' value={old} onChange={e=>setOld(e.target.value)}   className='form-control' placeholder='old ne'   /> </td>
  
  </tr>



  <tr>
    <td> page or group <input type='name'  value={pag} onChange={e=>setPag(e.target.value)} className='form-control' placeholder='pag'   /> </td>

    <td> sourch name/address <input type='name' value={chopname} onChange={e=>setchopname(e.target.value)}  className='form-control' placeholder='chopname'   /> </td>

    <td> uniqid product<input type='name' value={uniqid} onChange={e=>setallPid(e.target.value)}   className='form-control' placeholder='uniqid'   /> </td>
  
  </tr>







  

  
</tbody>


</table>

img link or img 
<input type='name' className='form-control container' value={img} onChange={e=>setImg(e.target.value)}  />



      </Modal>








      
    </div>
  )
}

export default ProductUpdate
