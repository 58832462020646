import React from 'react'

export default function About() {
  return (
    <div>
      <details>
        <p>

jflkjf
flfj
fljkf
flfj
fljflfljfl
fljf
fljf

        </p>
      </details>
    </div>
  )
}
