import { Card } from 'antd'
import React from 'react'

function All_update() {
  return (
    <div>
      <div className='row'>

<Card className='bg-primary p-1 m-3 col-4'>
  <p>service run-woking-report-update</p>
</Card>

<Card className='bg-primary p-1 m-3 col-4'>
  <p>service run-woking</p>
</Card>

      </div>
    </div>
  )
}

export default All_update
