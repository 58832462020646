import React,{useState, useEffect} from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import Typography from '@mui/joy/Typography';



import { useParams } from 'react-router-dom';

import Urlnodej from '../../Url/nodeApi'
import UrlLaravelapi from '../../Url/Url'
import { Image } from 'antd';
import { ConfirmationNumberOutlined, Delete, DeleteOutline, RemoveRedEye, ViewAgenda, ViewCarousel } from '@mui/icons-material';


import Urlnodeaj_api from '../../Url/nodeApi'


export default function TabsPricingExample({data}) {









  const [idget, setIddket]= useState()


   const {unidcode}=useParams();
const [alldatau, setDallll] = useState()
useEffect(() => {


  Getproduct_order();
  uergetAll();

}, [idget])





const [orderall, setAorderData] = useState([])
const [orderall_Alll_confrim, setAorderData_confrim] = useState([])

const Getproduct_order=()=>{





let id=unidcode;







Urlnodej.get(`/get_users_ordersidid/${id}`)

.then(res=>{
  console.log(' order  cccccccoooooonnnnnnffffffrrrimmmmmmmm !!!!!!!!!!!!!!!!!!!!!!!====================================');
  setAorderData_confrim(res.data);
  console.log(res.data);
  console.log('====================================');
})
.catch(error =>{
  console.log('api order error alll====================================');
  console.log('api order optin',error);
  console.log('====================================');
})


}











const uergetAll=()=>{
let id=unidcode;




  
Urlnodej.get(`get_users_orders_get_pending/${id}`)
.then(res =>{
  setDallll(res.data)
})
.catch(error =>{
  console.log(' get order li ====================================');
  console.log(error);
  console.log('====================================');
})

}



// order_confim_done_user_id_con

const Cofrimorder=(id,data)=>{


if(data=='confrom')

{


  Urlnodeaj_api.get(`order_confim_done_user_id/${id}`)
  .then(res =>{
    setIddket(id)

  })
  .catch(error => {
    alert(error)
  })











}else if(data=='deleted'){
 





  Urlnodeaj_api.get(`order_confim_done_user_id_deleted/${id}`)
  .then(res =>{
    setIddket(id)

  })
  .catch(error => {
    alert(error)
  })



}
else if(data=='confirm'){
  




  



  Urlnodeaj_api.get(`order_confim_done_user_id_con/${id}`)
  .then(res =>{
    setIddket(id)

  })
  .catch(error => {
    alert(error)
  })




}






}

  return (
    <Tabs
      variant="outlined"
      aria-label="Pricing plan"
      defaultValue={0}
      className='bg-dark text-white'
      sx={{
        width: '100%',
        borderRadius: 'lg',
        boxShadow: 'sm',
        overflow: 'auto',
      }}
    >
      <TabList
      bgcolor={'#000'}
        disableUnderline
        tabFlex={1}
        sx={{
          [`& .${tabClasses.root}`]: {
            fontSize: 'sm',
            fontWeight: 'lg',
            [`&[aria-selected="true"]`]: {
              color: 'primary.500',
              bgcolor: 'background.surface',
            },
            [`&.${tabClasses.focusVisible}`]: {
              outlineOffset: '-4px',
            },
          },
        }}
      >
        <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
          order new
        </Tab>
        <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
          order pendding
        </Tab>



        <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
          order accecpt  all
        </Tab>

        <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
          order reject all
        </Tab>
      </TabList>


      
      <TabPanel value={0}>



<table className='table'>

<thead>
  <tr>

  <th>count</th>
  <th>id</th>
  <th>img</th>
  <th>name</th>
  <th>model</th>
  <th>price</th>
  <th>action</th>

  </tr>
</thead>

        
<tbody>


{alldatau ?


alldatau.map((item, i)=>(
  <tr>
<td>{i+1}</td>
<td>{item.id}</td>

<td>

{item.img ?

<Image
width={100}
  height={100}
  src={`https://court.my-shopings.com/public/uploads_product/${item.img}`} 
/>

:

<Image alt='order' src={item.imglink} 

width={100}
  height={100}

/>


}

</td>
<td>{item.name}</td>
<td>{item.model}</td>
<td>{item.price}</td>
<td>


<button className='btn btn-danger m-1' onClick={()=>Cofrimorder(item.id,'confrom')}  > waiting </button>
<button className='btn btn-danger m-1' onClick={()=>Cofrimorder(item.id,'confirm')}  > confirom </button>
<button className='btn btn-danger m-1' onClick={()=>Cofrimorder(item.id,'deleted')} > <DeleteOutline /> </button>



<button className='btn btn-danger m-1' onClick={()=>Cofrimorder(item.id,'alldata')} > <RemoveRedEye   className=' text-black ' /> </button>
</td>



  </tr>
))

:

null

}


</tbody>



</table>








      </TabPanel>



      <TabPanel value={1}>
        
      

        <table className='table'>

<thead>
  <tr>

  <th>count</th>
  <th>id</th>
  <th>img</th>
  <th>name</th>
  <th>model</th>
  <th>price</th>
  <th>action</th>

  </tr>
</thead>

        
<tbody>


{alldatau ?


alldatau.map((item, i)=>(
  <tr>
<td>{i+1}</td>
<td>{item.id}</td>

<td>

{item.img ?

<Image
width={100}
  height={100}
  src={`https://court.my-shopings.com/public/uploads_product/${item.img}`} 
/>

:

<Image alt='order' src={item.imglink} 

width={100}
  height={100}

/>


}

</td>
<td>{item.name}</td>
<td>{item.model}</td>
<td>{item.price}</td>
<td>


<button className='btn btn-danger m-1' onClick={()=>Cofrimorder(item.id,'confrom')}  > waiting </button>
<button className='btn btn-danger m-1' onClick={()=>Cofrimorder(item.id,'confirm')}  > confirom </button>
<button className='btn btn-danger m-1' onClick={()=>Cofrimorder(item.id,'deleted')} > <DeleteOutline /> </button>

<select className='form-control' onChange={()=>Cofrimorder(item.id)} >

  <option value={'defaultChecked'} defaultChecked>select option</option>
  <option value={'confrom'} >confrom</option>
  <option value={'confirm'} >confrim</option>
  <option value={'reject'} >deleted</option>
  <option value={'waiting'} >deleted</option>
  <option value={'report'} >report</option>
  <option value={'deleted'} >deleted</option>
</select>


<button className='btn btn-danger m-1' onClick={()=>Cofrimorder(item.id,'alldata')} > <RemoveRedEye   className=' text-black ' /> </button>
</td>



  </tr>
))

:

null

}


</tbody>



</table>







     
      </TabPanel>



      <TabPanel value={2}>
       
    


     
  





      </TabPanel>




      <TabPanel value={3}>
        








        <Typography textColor="primary.400" fontSize="xl3" fontWeight="xl" mt={1}>
          <Typography
            fontSize="xl"
            borderRadius="sm"
            px={0.5}
            mr={0.5}
            sx={(theme) => ({
              ...theme.variants.soft.danger,
              color: 'danger.400',
              verticalAlign: 'text-top',
              textDecoration: 'line-through',
            })}
          >






    
          </Typography>
         
          <Typography fontSize="sm" textColor="text.secondary" fontWeight="md">
            
<h4>fkljalfjlajf53535353</h4>


          </Typography>




        </Typography>
      </TabPanel>



    </Tabs>
  );
}