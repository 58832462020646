import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useState,useEffect } from 'react';
import { Button, Modal, Space, Card, Col, Row, List  } from 'antd';

import { ToastContainer, toast } from 'react-toastify';


import axios from 'axios';


const AllGroup=()=>{

  const [catagoryall, setdatallcatGory] = useState([])

const setIddeleted=(id)=>{
  axios.delete(`https://court.my-shopings.com/public/api/catagory/${id}`)
  .then(res=>{

    alert(res.data.message)
  }).catch(error=>{
    alert('error')
  })
}
  
useEffect(() => {
  setInterval(() => {
    getidata();
  }, 700);
 
}, [])



const getidata=()=>{
   
try {

  axios.get('https://court.my-shopings.com/public/api/catagory')
  .then(res=>{
   
    if(res.data.data){



      setdatallcatGory(res.data.data);

    }else{
      setdatallcatGory([])
    }


  })
  
} catch (error) {
  setdatallcatGory([])

}finally{
 

  
}
}

  return (
<>


{catagoryall ?


<List
itemLayout="horizontal"
dataSource={catagoryall}
renderItem={(item, index) => <>

{item.groupp ?

  <List.Item>
    <List.Item.Meta
   
      // avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
      title={<p>{item.groupp ? 
<p> {item.groupp} </p>
      :
      null
      }</p>}
      // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
    />



<DeleteOutlined   onClick={()=>setIddeleted(item.id)}  twoToneColor="#eb2f96" />
  </List.Item>

:
null

}
</>
}
/>


:


null

}

</>
  )
}

const AllBrand=()=>{
  const [catagoryall, setdatallcatGory] = useState([])

const setIddeleted=(id)=>{
  axios.delete(`https://court.my-shopings.com/public/api/catagory/${id}`)
  .then(res=>{

    alert(res.data.message)
  }).catch(error=>{
    console.log('====================================');
    console.log('catagory deleted error');
    console.log('====================================');
  })
}
  
useEffect(() => {
  setInterval(() => {
    getidata();
  }, 700);
}, [])



const getidata=()=>{
   
try {

  axios.get('https://court.my-shopings.com/public/api/catagory')
  .then(res=>{
   
    if(res.data.data){



      setdatallcatGory(res.data.data);

    }else{
      setdatallcatGory([])
    }


  })
  
} catch (error) {
  alert('eror ');
}finally{
 

  
}
}

  return (
<>


{catagoryall ?


<List
itemLayout="horizontal"
dataSource={catagoryall}
renderItem={(item, index) => <>

{item.brand ?

  <List.Item>
    <List.Item.Meta
   
      // avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
      title={<p>{item.brand ? 
<p> {item.brand} </p>
      :
      null
      }</p>}
      // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
    />



<DeleteOutlined   onClick={()=>setIddeleted(item.id)}  twoToneColor="#eb2f96" />
  </List.Item>

:
null

}
</>
}
/>


:


null

}

</>
  )
}



const Allcatagory=()=>{


  const [catagoryall, setdatallcatGory] = useState([])

const setIddeleted=(id)=>{
  axios.delete(`https://court.my-shopings.com/public/api/catagory/${id}`)
  .then(res=>{

    alert(res.data.message)
  }).catch(error=>{
    alert('error')
  })
}
  
useEffect(() => {
  setInterval(() => {
    getidata();
  }, 700);
}, [])



const getidata=()=>{
   
try {

  axios.get('https://court.my-shopings.com/public/api/catagory')
  .then(res=>{
   
    if(res.data.data){



      setdatallcatGory(res.data.data);

    }else{
      setdatallcatGory([])
    }


  })
  
} catch (error) {
 setdatallcatGory([])
}finally{
 

  
}
}



  return (
<>


{catagoryall ?


<List
itemLayout="horizontal"
dataSource={catagoryall}
renderItem={(item, index) => <>

{item.catagory ?

  <List.Item>
    <List.Item.Meta
   
      // avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
      title={<p>{item.catagory ? 
<p> {item.catagory} </p>
      :
      null
      }</p>}
      // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
    />



<DeleteOutlined   onClick={()=>setIddeleted(item.id)}  twoToneColor="#eb2f96" />
  </List.Item>

:
null

}
</>
}
/>


:


null

}

</>
  )
}
















const LocalizedModal = () => {



  const showToastM = () => {
    toast.error("UnSuccess catagory add !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };


  const showToastMessage = () => {
    toast.success("Success Catagory add !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };



  const [open, setOpen] = useState(false);
  const [cagagry, setCatgory] = useState();


const datat={
  catagory:cagagry,
  id:JSON.parse(localStorage.getItem('id'))
}

  const showModal = () => {
    setOpen(true);
  };
useEffect(() => {
 gealld();

}, [])

const gealld=()=>{
  axios.get('https://court.my-shopings.com/public/api/user')
  .then(res=>{
    console.log(res.data);
console.log('====================================');
console.log(res.data);
console.log('====================================');


  }).catch(error => {
    // Handle error
    // setError(error);

    console.log('Upload successful:',error);
  });

}

// const [brand, setCatgorybrand] = useState('')
// const addcatagorybrand=()=>{

// alert('ok')

// }
const addcatagory=()=>{
 


  axios.post('https://court.my-shopings.com/public/api/Product_catagory',datat)
  .then(res=>{
    console.log(res.data);

    showToastMessage();

  }).catch(error => {
    // Handle error
    // setError(error);
   

    showToastM();
    console.log(error);
  });

  
}


  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
   
      
      <Button type="primary" onClick={showModal}>
        Add Catagory
      </Button>
      <Modal
        title="Add Catagory"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}

        okButtonProps={{
          disabled: true,
        }}
        cancelButtonProps={{
          disabled: false,
        }}

    >

     <div class="input-group mb-3">
  <input type="text" class="form-control" onChange={e=>setCatgory(e.target.value)} placeholder="Add Catagory" />
  <div class="input-group-append">
    <button class="btn btn-outline-secondary" onClick={addcatagory} type="button">add+</button>
  </div>
</div>



      </Modal>
    </>
  );
};


const Addgroup = () => {

  const showToastM = () => {
    toast.error("UnSuccess group add !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };


  const showToastMessage = () => {
    toast.success("Success group add !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [groupp, setCatgoryGroup] = useState('')

const [open3, setOpen3] = useState(false);
const showModal3 = () => {
  setOpen3(true);
};


// const datat={
//   groupadd:groupp,
//   id:JSON.parse(localStorage.getItem('id'))
// }


const handleOk3 = (e) => {
 
  const datat={
    groupadd:groupp,
    id:JSON.parse(localStorage.getItem('id'))
  }

  axios.post('https://court.my-shopings.com/public/api/Product_catagorygorup',datat)
  .then(res=>{
    console.log(res.data);
    showToastMessage();

// alert(res.data.message);
  }).catch(error => {
    // Handle error
    // setError(error);
    showToastM();
    console.log(error);

  });

  
};
const handleCancel3 = (e) => {
  console.log(e);
  setOpen3(false);
};



 return (
  <>
 
  <Button type="primary" onClick={showModal3}>
        Add group name
      </Button>
  <Modal
  title="Brand name add"
  open={open3}
  onOk={handleOk3}
  onCancel={handleCancel3}
  okButtonProps={{
    disabled: false,
  }}
  cancelButtonProps={{
    disabled: true,
  }}
>
  



  <div class="input-group mb-3">
<input type="text" class="form-control" onChange={e=>setCatgoryGroup(e.target.value)} placeholder="Add group name" />
<div class="input-group-append">

</div>
</div>




</Modal>

</>
 )

};







const Confirm = () => {

  const showToastM = () => {
    toast.error("UnSuccess Brand add !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };


  const showToastMessage = () => {
    toast.success("Success Brand add !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [brand, setCatgorybrand] = useState('')

const [open3, setOpen3] = useState(false);
const showModal3 = () => {
  setOpen3(true);
};


const datat={
  brandd:brand,
  id:JSON.parse(localStorage.getItem('id'))
}


const handleOk3 = (e) => {
 
  

  axios.post('https://court.my-shopings.com/public/api/Product_catagorybrand',datat)
  .then(res=>{
    console.log(res.data);
    showToastMessage();


  }).catch(error => {
    // Handle error
    // setError(error);
    showToastM();
    console.log(error);
  });

  
};
const handleCancel3 = (e) => {
  console.log(e);
  setOpen3(false);
};



 return (
  <>
  
  <Button type="primary" onClick={showModal3}>
        Add Brand name
      </Button>
  <Modal
  title="Brand name add"
  open={open3}
  onOk={handleOk3}
  onCancel={handleCancel3}
  okButtonProps={{
    disabled: false,
  }}
  cancelButtonProps={{
    disabled: true,
  }}
>
  



  <div class="input-group mb-3">
<input type="text" class="form-control" onChange={e=>setCatgorybrand(e.target.value)} placeholder="Add Brand name" />
<div class="input-group-append">

</div>
</div>




</Modal>

</>
 )

};


const All_Setting = () => {
const [nunmber, setcAhtor] = useState('1')
  const Getcatagory=()=>{
    switch (nunmber) {
      case '1': return <Allcatagory />
        
      case '3': return <AllBrand />
      
      default:return <AllGroup />
           
    }
  }







  return (
    <>
    
     <div className='bg-dark p-4'>

   



      <Row gutter={16}>
    <Col span={8}>
      <Card title=" Brand or Catagory add" bordered={false}>
        


      <Space>
        <LocalizedModal />

        <Confirm  />



    
       
      </Space>
     



    
  








      </Card>
    </Col>
    <Col span={8}>
      <Card title="add group option" bordered={false}>
      <Addgroup />
      </Card>
    </Col>
    <Col span={8}>
      <Card title="remove catagory and brand name and group" bordered={false}>
       

<select onChange={(e)=>setcAhtor(e.target.value)} className='form-control'>

<option >remove catagory select</option>
<option value={'1'}>catagory</option>
<option value={'3'}>brand </option>
<option value={'4'}>group</option>


</select>
{Getcatagory()}


      </Card>
    </Col>
  </Row>





  </div>
    </>
  );
};
export default All_Setting;



