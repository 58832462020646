import React,{useEffect, useState} from 'react'
import Chart_all from './Chart/Chart_all'
import Chart_alllume from './Chart/clume'
import Clupoll from './Chart/Clupoll'
import Clume_tro from './Chart/Clume_tro'
import MultLine from './Chart/MultLine'
import Multer_lin from './Chart/Multer_lin'
import Rcart from './Chart/Rcart'
import Area_link from './Chart/Area_link'
import BarChartbar from './Chart/BarChartbar'
import Staccharbar from './Chart/Staccharbar'
import Pei_plot from './Chart/Pei_plot'
import Pie_quate_circle from './Chart/Pie_quate_circle'
import Liqued from './Chart/Liqued'
import Them from './Chart/Them'
import Clumm from './Chart/Clumm'
import SetLine from './Chart/SetLine'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Card, Col, Row,Breadcrumb } from 'antd';
import { SupervisedUserCircleOutlined } from '@mui/icons-material'
import { Badge, Divider } from '@mui/material'

import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios'


import nodejapi from '../dashbord/Url/nodeApi'


export default function One_home() {
const [allpending, setTlpending] = useState(0)

useEffect(() => {
setInterval(() => {
  getProductallOrder();


  getProductallOrder_get_users();

  getProductallOrder_getPageView();
  getProductallOrderReject();

  getProductallOrder_ting();

  getProductallOrder_confrim();
}, 3000);

}, [])



// products_order_complute  products_order_waiting  products_order_al  products_order_pending

// products_order_reject3




const [all_ueers, setsAlluesr] = useState()
const getProductallOrder_get_users=()=>{

    nodejapi.get('get_user_data')
  .then(res =>{
    setsAlluesr(res.data.length)
    console.log('pending all order ');
    console.log(res.data);
  }).catch(error=>{
    console.log('error get data',error);
  })
}

// get_user_data




const [con_getPageView, setstate__getPageView] = useState()
const getProductallOrder_getPageView=()=>{

    nodejapi.get('getPageView')
  .then(res =>{
    setstate__getPageView(res.data[0].view)
    console.log('pending all order ');
    console.log(res.data);
  }).catch(error=>{
    console.log('error get data',error);
  })
}

// getPageView


const [confirm_ting, setstate_ting] = useState()
const getProductallOrder_ting=()=>{

    nodejapi.get('products_order_waiting')
  .then(res =>{
    setstate_ting(res.data.length)
    console.log('pending all order ');
    console.log(res.data);
  }).catch(error=>{
    console.log('error get data',error);
  })
}






const [confirm_rejct, setstate_rejct] = useState()
const getProductallOrderReject=()=>{

    nodejapi.get('products_order_reject3')
  .then(res =>{
    setstate_rejct(res.data.length)
    console.log('pending all order ');
    console.log(res.data);
  }).catch(error=>{
    console.log('error get data',error);
  })
}



const [confirm, setstateconfirm] = useState()
const getProductallOrder=()=>{

    nodejapi.get('products_order_complute')
  .then(res =>{
    setstateconfirm(res.data.length)
    console.log('pending all order ');
    console.log(res.data);
  }).catch(error=>{
    console.log('error get data',error);
  })
}

const [confrim, setTateconfilrm] = useState()
const getProductallOrder_confrim=()=>{
  nodejapi.get('products_order_pending')
.then(res =>{
  setTlpending(res.data.length)
  console.log('pending all order ');
  console.log(res.data);
}).catch(error=>{
  console.log('error get data',error);
})
}


  return (
    <div className='container'> 



<Breadcrumb>
    <Breadcrumb.Item href='/home'>dashbord</Breadcrumb.Item>
    <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
    <Breadcrumb.Item>page</Breadcrumb.Item>
  </Breadcrumb>


<div style={{ background: '#ECECEC', padding: '30px',margin:9, }}>
    <Row gutter={16}>
      <Col span={8}>
        <Card title={<p style={{color:'aqua'}}> new order</p>} className='card bg-dark text-white' style={{color:'aqua'}} bordered={false}>

        <PeopleAltIcon style={{color:'aqua'}}  />
<Badge style={{marginLeft:'19px',color:'aqua'}}>{allpending}</Badge>
<LinearProgress color="secondary" />
        </Card>




      </Col>




      <Col span={8}>
        <Card title={<p style={{color:'aqua'}}> pendin order</p>} className='card bg-dark text-white' style={{color:'aqua'}} bordered={false}>

        <PeopleAltIcon style={{color:'aqua'}}  />
<Badge style={{marginLeft:'19px',color:'aqua'}}>{confirm_ting}</Badge>
<LinearProgress color="secondary" />
        </Card>




      </Col>





      <Col span={8} className=''>
        <Card title={<p style={{color:'aqua'}}> confirm order</p>} className='card bg-dark text-white' style={{color:'aqua'}} bordered={false}>

        <PeopleAltIcon style={{color:'aqua'}}  />
<Badge style={{marginLeft:'19px',color:'aqua'}}>{confirm}</Badge>
<LinearProgress color="secondary" />
        </Card>




      </Col>




   <Divider />






    </Row>
  </div>



  <div style={{ background: '#ECECEC', padding: '30px',margin:9, }}>
    <Row gutter={16}>
      <Col span={8}>
        <Card title={<p style={{color:'aqua'}}> Reject order</p>} className='card bg-dark text-white' style={{color:'aqua'}} bordered={false}>

        <PeopleAltIcon style={{color:'aqua'}}  />
<Badge style={{marginLeft:'19px',color:'aqua'}}>{confirm_rejct}</Badge>
<LinearProgress color="secondary" />
        </Card>




      </Col>




      <Col span={8}>
        <Card title={<p style={{color:'aqua'}}> total  users</p>} className='card bg-dark text-white' style={{color:'aqua'}} bordered={false}>

        <PeopleAltIcon style={{color:'aqua'}}  />
<Badge style={{marginLeft:'19px',color:'aqua'}}>{all_ueers}</Badge>
<LinearProgress color="secondary" />
        </Card>




      </Col>





      <Col span={8} className=''>
        <Card title={<p style={{color:'aqua'}}> total  view</p>} className='card bg-dark text-white' style={{color:'aqua'}} bordered={false}>

        <PeopleAltIcon style={{color:'aqua'}}  />
<Badge style={{marginLeft:'19px',color:'aqua'}}>{con_getPageView}</Badge>
<LinearProgress color="secondary" />
        </Card>




      </Col>




   <Divider />






    </Row>
  </div>







    <div className='row'> 


    {/* {new Intl.NumberFormat('en-IN').format(amount)} */}

    <div class="form-group card p-3 col-4 text-white bg-dark m-4" style={{height:'30%'}}>




<Chart_alllume />

</div>




<div class="form-group card p-3 text-white col-4 bg-dark m-4">



<Clupoll />
</div>




<div class="form-group card text-white p-3 col-2 bg-dark m-4">

8768




<Clume_tro />

</div>

<hr/>


   <div class="form-group card p-3 col-4 bg-dark m-4 text-white">

  786
<MultLine />
  </div>



  
  <div class="form-group card p-3 col-4 bg-dark m-4 text-white">

867
<Multer_lin />
</div>




<div class="form-group card p-3 col-2 bg-dark m-4 text-white">

8768
<Rcart  />
</div>


<hr/>

<div class="form-group card p-3 col-4 bg-dark m-4 text-white">

876
<Area_link  />
</div>


<div class="form-group card p-3 col-4 bg-dark m-4 text-white">

876
<Staccharbar  />
</div>


<div class="form-group card p-3 col-2 bg-dark m-4 text-white">

876
<BarChartbar />
</div>

<hr/>

<div class="form-group card p-3 col-4 bg-dark m-4 text-white">

876
<Pei_plot  />

</div>


<div class="form-group card p-3 col-4 bg-dark m-4 text-white">

876
<Liqued  />
</div>

<div class="form-group card p-3 col-2 bg-dark m-4 text-white">

876
<Pie_quate_circle  />
</div>


<hr/>


<div class="form-group card p-3 col-4 bg-dark m-4 text-white">

876
<Them />
</div>

<div class="form-group card p-3 col-4 bg-dark m-4 text-white">

876
<SetLine />
</div>


<div class="form-group card p-3 col-2 bg-dark m-4 text-white">

876
<Clumm  />
</div>

    </div>
    </div>
  )
}
