import React from 'react'

function all_Gift() {
  return (
    <div>
      Gift 
    </div>
  )
}

export default all_Gift
