

import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import  useSound from 'use-sound'
import boopFff from '../sound/mouse-click-104737.mp3'















import {
    DatabaseFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
 
  VideoCameraOutlined,
  UserOutlined,
  BellOutlined,
  MessageOutlined,

  DownOutlined,
   SmileOutlined,
   EditOutlined,
   AppstoreOutlined,
   LineChartOutlined,

   UsergroupAddOutlined,
   RetweetOutlined,

   GiftOutlined,

   GifOutlined,
   ShopOutlined,

   MoneyCollectOutlined,

   SettingOutlined,

   

   

   
} from '@ant-design/icons';



import { Layout, Menu, Button, theme,  Space, Badge,

  Dropdown, 


} from 'antd';
import Search from 'antd/es/input/Search';
import { Navigate, useNavigate } from 'react-router-dom';




import One_home from './dashbord/One_home';
import Product_upload from './dashbord/Product_upload';
import ProductUpdate from './dashbord/ProductUpdate';
import Product_Datils from './dashbord/Product_Datils';
import Product_return from './dashbord/Product_return';
import Product_sell from './dashbord/Product_sell';
import Product_store from './dashbord/Product_store';
import User_list from './dashbord/User_list';
import All_money_request from './dashbord/All_money_request';
import All_Gift from './dashbord/all_Gift';
import All_Order_list from './dashbord/All_Order_list';
import All_Setting from './dashbord/All_Setting';
import Winner_all from './dashbord/Winner_all';
import About from './dashbord/About';
import Profile_get from './dashbord/Admin_profile';
import { Avatar, Stack, colors } from '@mui/material';











const { Header, Sider, Content } = Layout;












const Home = () => {



  var nagivation=useNavigate();
useEffect(()=>{

var data =localStorage.getItem('id');
if(data){
  nagivation('/home');
}else{
  nagivation('/');
}


},[])

  
// const items = [
//   {
//     key: '1',
//     label: (
//       <h6>Logout</h6>
//     )
//   },
//   {
//     key: '2',
//     label: (
//       <h6> Setting</h6>
//     ),
//     icon: <SmileOutlined />,
   
//   },

  
// ];

let Imgg = localStorage.getItem('img');

 
  var nagivation=useNavigate();
useEffect(()=>{

var data =localStorage.getItem('name');
if(data){
  nagivation('/home');
}else{
  nagivation('/');
}

});



  let audio = new Audio('');
  const [collapsed, setCollapsed] = useState(false);

  const Sidbarclick=()=>{
    setCollapsed(!collapsed);
    play();
  }
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onlick=(items)=>{
    setNambuerca(items.key);

    play();

  }
const [play]=useSound(boopFff);

var uername=localStorage.getItem('name');




  const valueseah=(e)=>{


    alert(e)
  }


const [namber, setNambuerca] = useState('1')

  const  projeccomponet=()=>{
switch (namber) {
  case '1': return <One_home />
  case '2': return <Product_upload />
  case '3': return <ProductUpdate />
  case '4': return <Product_store />
  case '5': return <Product_sell />
  case '6': return <User_list />
  case '7': return <Product_return />
  case '8': return <Winner_all />
  case '9': return <All_Gift />
  case '10': return <All_Order_list />
  case '11': return <All_money_request />
  case '12': return <All_Setting />
  case '13': return <About />

  case '14': return <Profile_get  />
    
  

  default: return <h3>not componet add</h3>
   
}

  }





const Logoutt=()=>{
 
  
  localStorage.removeItem('olds');
  localStorage.removeItem('catagoris');
localStorage.removeItem('id');
localStorage.removeItem('name');
localStorage.removeItem('img');


nagivation('/');

}






  const menu = (
    <Menu>
      <Menu.Item info icon={<SmileOutlined />} onClick={()=>Logoutt()}>
        
         Logout 
       
      </Menu.Item>
      {/* <Menu.Item disabled icon={<SmileOutlined />}>
        <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
          2nd menu item (disabled)
        </a>
      </Menu.Item> */}

<Menu.Item danger icon={<SmileOutlined />}   onClick={()=>setNambuerca('14')}>profile </Menu.Item>
     
      <Menu.Item danger icon={<SmileOutlined />} onClick={()=>setNambuerca('15')}>About </Menu.Item>
    </Menu>
  );



  return (
    <Layout>
      <Sider theme="dark" trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />

        <h3 style={{color:'white', margin:'4px',padding:'4px'}}>my-shopings.com</h3>
        <Menu
           theme="dark"
          mode="inline"
       onClick={onlick}
          defaultSelectedKeys={['1']}
          items={[

            
            {
              key: '1',
              icon: <DatabaseFilled />,
              label: 'Dashbord',
            },

            {
              key: '2',
              icon: <UploadOutlined />,

              label: <Badge style={{color:'wheat'}} count={0} offset={[3,10]}>


            
             <h6 style={{color:'wheat'}} >{  'Product Upload'  }</h6> 
              
              
              
                </Badge>
                
              
            },



            {
              key: '3',
              icon: <EditOutlined />,
              label: <Badge style={{color:'wheat'}} count={3} offset={[1,-0]}>


            
              <h6 style={{color:'wheat'}} >{  'Product Update'  }</h6> 
               
               
               
                 </Badge>
            },

            {
              key: '4',
              icon: <AppstoreOutlined />,
              label: <Badge style={{color:'wheat'}} count={4} offset={[14,10]}>


            
              <h6 style={{color:'wheat'}} >{  'Product Store'  }</h6> 
               
               
               
                 </Badge>
            },
            {
              key: '5',
              icon: <LineChartOutlined />,
              label:
              <Badge style={{color:'wheat'}} count={4} offset={[14,10]}>


            
              <h6 style={{color:'wheat'}} >{  'Product Sell'  }</h6> 
               
               
               
                 </Badge>
             
            },



            {
              key: '6',
              icon: <UsergroupAddOutlined />,
              label:  <Badge style={{color:'wheat'}} count={4} offset={[14,10]}>


            
              <h6 style={{color:'wheat'}} >{  'Users list'  }</h6> 
               
               
               
                 </Badge>
             
            },


            
            {
              key: '7',
              icon: <RetweetOutlined /> ,
              label:  <Badge style={{color:'wheat'}} count={4} offset={[14,10]}>


            
              <h6 style={{color:'wheat'}} >{  'Product retrun'  }</h6> 
               
               
               
                 </Badge>
            },


            {
              key: '8',
              icon: <GiftOutlined />,
              label: <Badge style={{color:'wheat'}} count={4} offset={[14,10]}>


            
              <h6 style={{color:'wheat'}} >{  'Winner'  }</h6> 
               
               
               
                 </Badge>
              
              
            },


            {
              key: '9',
              icon: <GifOutlined />,
              label: <Badge style={{color:'wheat'}} count={4} offset={[4,-4]}>


            
              <h6 style={{color:'wheat'}} >{  'Product Gift list'  }</h6> 
               
               
               
                 </Badge>
            },
            {
              key: '10',
              icon: <ShopOutlined /> ,
              label: <Badge style={{color:'wheat'}} count={4} offset={[-14,-5]}>


            
              <h6 style={{color:'wheat'}} >{'Product Order all'  }</h6> 
               
               
               
                 </Badge>
            },

            {
              key: '11',
              icon: <MoneyCollectOutlined />,
              label:
              <Badge style={{color:'wheat'}} count={4} offset={[4,-1]}>


            
              <h6 style={{color:'wheat'}} >{  'Money Request'  }</h6> 
               
               







               
                 </Badge>
             
            },

            {
              key: '12',
              icon: <SettingOutlined />,
              label: 
              
              <Badge style={{color:'wheat'}} count={0} offset={[14,10]}>


            
              <h6 style={{color:'wheat'}} >{ 'Setting'  }</h6> 


               
               
               
                 </Badge>
             
              
             
            },


            
            {
              key: '13',

              icon: <UploadOutlined />,
              label: 'About me',
            },


            



          ]}
        > 
     

        </Menu>



      </Sider>


      <Layout>
        <Header    theme="dark" style={{ padding: 0,}}>

      
       


<div className='' style={{position:'absolute', right:0}} >

<li className='btn'>

{/* <Badge.Ribbon text="md rashdul islam" color="cyan">
     
    </Badge.Ribbon> */}






 </li>






<li className='btn' style={{top:1}}>



<Search placeholder='serat ant ' onSearch={valueseah}    style={{width:200}} />


</li>



 <li className='btn m-1'>     <Badge count={50} offset={[10, 10]}>


<MessageOutlined  className='text-white larger' style={{fontSize:'25px'}}  /> 




  </Badge>
  
  
   </li>



<li className='btn m-1'>     <Badge count={5} offset={[10, 10]}>


<BellOutlined  className='text-white larger' style={{fontSize:'25px'}}  /> 




  </Badge>
  
  
   </li>





   




<li className='btn'> 





{/* <Dropdown>
    <a>
      <Space>
      <Badge className='text-white'> {uername}</Badge>
        <DownOutlined />
      </Space>
    </a>
  </Dropdown> */}



<Dropdown overlay={menu}>













    <a onClick={(e) => e.preventDefault()}>
      <Space>



      <Stack direction="row" spacing={2}>

<Badge
  overlap="circular"
  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  variant="dot"
>
  <Avatar alt={uername} src={`https://court.my-shopings.com/public/admin_img/${Imgg}`} />
</Badge>
</Stack>




      {uername}
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>







 </li>





 



</div>



<Button
       className='text-white'
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={Sidbarclick}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
              
           
            }}
          />





       
        </Header>






        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
     


{projeccomponet()}






        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;