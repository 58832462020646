import React, { useEffect, useState } from 'react'

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';

import Container from '@mui/material/Container';





import TextField from '@mui/material/TextField';



import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';












import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';










import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Collapse, DatePicker, Input, Popover, Table } from 'antd';


import Api from '../dashbord/Url/Url';
import axios from 'axios';
import { type } from '@testing-library/user-event/dist/type';
import { Html } from '@mui/icons-material';
import { TypographyInheritContext } from '@mui/joy/Typography/Typography';












const items = [
  {
    key: '1',
    label: 'This is panel header 1',
    children: <p>{'dmd '}</p>,
  },
  {
    key: '2',
    label: 'This is panel header 2',
    children:  <p>{'dmd '}</p>,
  },
 
  {
    key: '3',
    label: 'This is panel header 3',
    children:  <p>{'dmd '}</p>,
  },

];







const content = (
  <div>
    <p>Content</p>
    <p>Content</p>
  </div>
);








function Kisti_hisab_account() {





  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };




const [name, seTname]= useState('');
  const [getid, setId] = useState('');
  const [date, setDatass] = useState('');
  const [taka, setTaka] = useState('');
  const [getsetId, setValuename] = useState('');

  const [datass, seTdata]= useState([]);



  // const alldataupdate=[
  //   {
  //   id:getsetId,
  //   takas:taka,
  //       getdate:date
  //   }
  // ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'id',
    },
    {
      title: 'phone',
      dataIndex: 'phone',
      key: 'id',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'id',
    },

    {
      title: 'action',
      name:'name',
      render: (text, record) => (
      
    

       <Button >deleted</Button>

     

      



       ),
     },
  ];




  useEffect(() => {
   
      getdataa();
   
  }, [])


  const getdataa=()=>{

    Api.get(`all_users`)
    .then(res =>{
      // setget_Balance(res.data[0].Balance)
      // setValue(res.data[0].Balance)
  
      console.log(res.data);
      seTdata(res.data.data);
      console.log(' select profile img ');
    })
    .catch(error =>{
  
     
      console.log(' get order li ====================================');
      console.log(error);
      console.log('====================================');
    })


  }
  



  const formData = new FormData();


  formData.append('userid', getsetId);

    formData.append('getdate', date);

    formData.append('taka', taka);














const datasubmite =()=>{

 


  axios.post('https://court.my-shopings.com/public/api/kisti_hisab_enter',formData,{
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  .then(res=>{
    console.log(res.data.data);
    
    console.log('ok complete ');
    console.log('ok complete ');
    console.log('ok complete ');
   

    alert(res.data.data);
   

    setTimeout(() => {
     
    }, 4000);
   



  }).catch(error => {
   
    // Handle error
    // setError(error);



alert(error);


    console.log(error);
    console.log('cach error data newtworking ');
    console.log(error);
    console.log('cach error data newtworking ');
    console.log('cach error data newtworking ');
    console.log('cach error data newtworking ');



    
     
alert('network error');

    
   
    console.log(error);


  });

 










}












const handleClose = () => {
  



    
  setOpen(false);
};

  return (

    


<React.Fragment style="background:'pink'">






      <CssBaseline />
  
        <AppBar>
          <Toolbar>
            <Typography variant="h6" component="div">
             My-shopings.com
            </Typography>
          </Toolbar>
        </AppBar>
    
      <Toolbar />



      <Container>







      <Popover content={content} title="Title">
    <Button type="primary">Hover me</Button>
  </Popover>






      <Card sx={{ minWidth: 275 }}>
      <CardContent>
      



      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >





<FormControl fullWidth>
        <InputLabel variant="standard"   htmlFor="uncontrolled-native">
           সিলেক্ট করুন   <p>{getsetId} </p> 
        </InputLabel>
        <NativeSelect
     
        onChange={e=>setValuename(e.target.value)}
      
          inputProps={{
            name: 'age',
            id: 'uncontrolled-native',
          }}
        >


        {datass.map((index, id)=>

     (
          <option key={id} value={index.id}>{index.name}</option>
         
        ))}
          
        </NativeSelect>
      </FormControl>




{name}

      <TextField label="নাম:- "  onChange={e=>seTname(e.target.value)} placeholder=' নাম ' color="secondary" focused />

      
      <TextField label="" type='number' onChange={e=>setTaka(e.target.value)} placeholder='সঞ্চয় টাকা লেখুন' color="secondary" focused />


      <TextField label=""  onChange={e=>setDatass(e.target.value)}  type='Date' placeholder='current date'  color="secondary" focused />



      <TextField label="ঠিকানা :-" placeholder='আপনার ঠিকানা' color="secondary" focused />

      
      
    </Box>


















    <Button variant="outlined" onClick={handleClickOpen}>
       সাবমিট করুন
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
       
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        টাকা সঞ্চায় করুন এখানে 
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           আপনার টাকা ডাটা বেজে সেভ করা হচ্ছে। আপনার যদি কোন ভূল না হয় তাহলে সাবমিটে ক্লিক করুন <span style={{color:'red'}}> এবং যদি ভুল হয় বাতিলে প্রেস করুন।  </span>
          </DialogContentText> 
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
         বাতিল করুন
          </Button>
          <Button onClick={datasubmite}>টাকা সেভ   <span Loading></span></Button>
        </DialogActions>
      </Dialog>



<Card>


<Table dataSource={datass}  columns={columns} />

</Card>




{/* {datass.map(()=> {

<>



</>


})} */}















<CardContent>


{/* <LoadingButton
          color="secondary"
          onClick={handleClick}
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained"
        >
          <span>submite</span>
        </LoadingButton> */}

























</CardContent>


      </CardContent>
      <CardActions>
        <Button size="small">more option</Button>
      </CardActions>
    </Card>





    <Collapse accordion items={items} />;













      </Container>
    </React.Fragment>






  )
}







export default Kisti_hisab_account