import React,{useEffect} from 'react'
import '../../App.css'
import { Link,useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'



function Login() {
let uernavigarot=useNavigate();

var nagivation=useNavigate();
useEffect(()=>{

var data =localStorage.getItem('id');
if(data){
  nagivation('/home');
}else{
  nagivation('/');
}


},[])

var url='https://court.my-shopings.com/public/api';

 
	const [namen,seTnametate]=useState({
		name:'',
		pass:''
	})

	const handerll=(e)=>{
		e.persist();
		seTnametate({...namen, [e.target.name]: e.target.value});
	}
const data={
	name:namen.name,
	phone:namen.pass
}
	const [npaa,seTpaa]=useState()

	let axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			"Access-Control-Allow-Origin": "*",
		}
	  };

	  const customHeaders = {
		"Content-Type": "application/json",
	}


const Loging=(e)=>{
	e.preventDefault();
axios.post(url+'/loginadmin',data)
.then(res =>{
	
	console.log(res.data.data); 

	if(res.data.data.length ==1 ){
localStorage.setItem('name', res.data.data[0].name);
localStorage.setItem('id', res.data.data[0].id);


   localStorage.setItem('img', res.data.data[0].img);
uernavigarot('/home');


	}else{
alert('error worng id');
	}

	
})


// fetch('https://api.my-shopings.com/login', {
//     method: "POST",
//     headers: customHeaders,
//     body: JSON.stringify({
// 		name:namen,
// 		pass:npaa
// 	}),
// })
//     .then((response) => response.json())
//     .then((data) => {
//         console.log(data);
//     });
}


  return (
    <div className='container'>
      <div class="modal bg-dark"  style={{display:'block',}} tabindex="-1" role="dialog">
  <div class="modal-dialog bg-dark" role="document">
    <div class="modal-content bg-dark p-4 mt-5">
   
      


      <div class="container h-100 p-1 ">
		<div class="d-flex justify-content-center h-100">
			<div class="user_card">
				<div class="d-flex justify-content-center">
					<div class="brand_logo_container">
					<img src={require('../logo/logo192.png')} class="brand_logo" alt="Logo" />
					</div>
				</div>
				<div class="d-flex justify-content-center form_container">
					<form >
						<div class="input-group mb-3">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-user"></i></span>
							</div>
							<input type="text" name='name' value={namen.name}  onChange={handerll}  class="form-control input_user"  placeholder="email/phone" />
						</div>
						<div class="input-group mb-2">
							<div class="input-group-append">
								<span class="input-group-text"><i class="fas fa-key"></i></span>
							</div>
							<input type="password" name="pass" value={namen.pass}  onChange={handerll} class="form-control input_pass"  placeholder="password" />
						</div>
						<div class="form-group">
							<div class="custom-control custom-checkbox">
								{/* <input type="checkbox" class="custom-control-input" id="customControlInline" /> */}
								{/* <label class="custom-control-label" for="customControlInline">Remember me</label> */}
							</div>
						</div>
							<div class="d-flex justify-content-center mt-3 login_container">
				 <button className='btn btn-warning container' onClick={Loging}>Login</button>
				   </div>
					</form>
				</div>
		
				<div class="mt-4">
					<div class="d-flex justify-content-center links">
						Don't have an account? <Link to={'Regg'}>Register</Link>
					</div>
					<div class="d-flex justify-content-center links">
						<a href="#">Forgot your password?</a>
					</div>
				</div>
			</div>
		</div>
	</div>






 
    </div>
  </div>
</div>
    </div>
  )
}

export default Login
