import React,{useEffect,useState} from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { Breadcrumb, Rate, Space } from 'antd';

import { useParams } from 'react-router-dom';

import Urlnodej from './Url/nodeApi'

export default function UserCard({data}) {


  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];


  const [value, setValue] = useState(0);



  const {unidcode}=useParams();

useEffect(() => {
  uergetDataall();
}, [])


useEffect(() => {
  uergetBalace();
}, [])





const [amount, setget_Balance] = useState(0)

const uergetBalace=()=>{
  let id=unidcode;
    
  Urlnodej.get(`user_balance_id_sum/${id}`)
  .then(res =>{
    setget_Balance(res.data[0].Balance)
    setValue(res.data[0].Balance)

    console.log(res.data[0].Balance);
    
    console.log('get profile data all order ');
  })
  .catch(error =>{

    setget_Balance([]);
    console.log(' get order li ====================================');
    console.log(error);
    console.log('====================================');
  })
  

}







  const [uerdatain, setget_user] = useState([])

  const uergetDataall=()=>{
    let id=unidcode;
      
    Urlnodej.get(`get_user_data/${id}`)
    .then(res =>{
      setget_user(res.data[0])
      console.log(res.data);
      
      console.log('get profile data all order ');
    })
    .catch(error =>{

      setget_user([]);
      console.log(' get order li ====================================');
      console.log(error);
      console.log('====================================');
    })
    
  
  }


  
  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: { xs: 'auto', sm: 'initial' },
      }}
    >




<Breadcrumb
    items={[
      {
        title: 'Home',
      },
      {
        title: 'order dashbord',
      },
      
      {
        title: 'Profile ',
      },
    ]}
  />






      <Box
        sx={{
          position: 'absolute',
          display: 'block',
          width: '1px',
          bgcolor: 'warning.300',
          left: '500px',
          top: '-24px',
          bottom: '-24px',
          '&::before': {
            top: '4px',
            content: '"edit"',
            display: 'block',
            position: 'absolute',
            right: '0.5rem',
            color: 'text.tertiary',
            fontSize: 'sm',
            fontWeight: 'lg',
          },
          '&::after': {
            top: '4px',
            content: '"profile"',
            display: 'block',
            position: 'absolute',
            left: '0.5rem',
            color: 'text.tertiary',
            fontSize: 'sm',
            fontWeight: 'lg',
          },
        }}
      />
      <Card
        orientation="horizontal"
        sx={{
          width: '100%',
          flexWrap: 'wrap',
          [`& > *`]: {
            '--stack-point': '500px',
            minWidth:
              'clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)',
          },
          // make the card resizable for demo
          overflow: 'auto',
          resize: 'horizontal',
        }}
      >
        <AspectRatio flex ratio="1" maxHeight={182} sx={{ minWidth: 182 }}>
          <img
            src={`https://court.my-shopings.com/public/profile_users/${uerdatain.img}`}
            srcSet={`https://court.my-shopings.com/public/profile_users/${uerdatain.img}`}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
        <CardContent>
          <Typography fontSize="xl" fontWeight="lg">
           {uerdatain.name}
          </Typography>
          <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
          {uerdatain.phone}

          </Typography>

          <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
          {uerdatain.address}
          
          </Typography>
          <Sheet
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              p: 1.5,
              my: 1.5,
              display: 'flex',
              gap: 2,
              '& > div': { flex: 1 },
            }}
          >
            <div>
              <Typography level="body-xs" fontWeight="lg">
                money widtro
              </Typography>
              <Typography fontWeight="lg">34</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg">
                Balance
              </Typography>
              <Typography fontWeight="lg">
              {'tk '}
              {new Intl.NumberFormat('en-IN').format(amount)}
          
              
              </Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg">
                Rating





              </Typography>
              <Typography fontWeight="lg">

              <Space>
      <Rate tooltips={desc} onChange={setValue} value={value} />
      {value ? <span>{desc[value - 1]}</span> : ''}
    </Space>



              </Typography>
            </div>
          </Sheet>
          <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 } }}>
            <Button variant="outlined" color="neutral">
              Edit
            </Button>
            <Button variant="solid" color="primary">
              Widtro money
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}