import React,{useEffect,useState} from 'react'
import axios from 'axios'

export default function Service() {


useEffect(() => {
geapi();
}, [])

const geapi=()=>{
  // axios.get('https://geo.ipify.org/api/v2/country,city,vpn?apiKey=at_TB4kj2ZYuZaADF88ajuM0R6r5JbQu&ipAddress=103.163.170.25')
axios.get('https://geo.ipify.org/api/v2/country,city,vpn?=103.163.170.25')
  .then(res =>{

    console.log('====================================');
    console.log(res.data.location);
    console.log('====================================');

    
  }).catch(error=>{

    console.log('eeeeeeeeeerrrrrrrrrrrroo apiaaaaaaaaaapppppppppppppppiiiiiiiiiiiiiiiii====================================');
    console.log(error);
  })
}


  return (
    <div>
      <h3>service</h3>
    </div>
  )
}
