import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';
import axios from 'axios';

const Pei_plot = () => {

  useEffect(() => {
    getlall();
  }, [])
  



const [data, setDatali] = useState([])

const getlall=()=>{

axios.get('https://court.my-shopings.com/public/api/all_users')
.then(res =>{
  setDatali(res.data.data)
})
.catch(errror =>{
  console.error(errror);
})

}



  const datat = [
    {
      type: 'ebrahim',
      value: 27,
    },
    {
      type: 'hena',
      value: 25,
    },
    {
      type: 'ma',
      value: 18,
    },
    {
      type: 'baba',
      value: 15,
    },
    {
      type: 'mama',
      value: 10,
    },
    {
      type: 'dadi',
      value: 5,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.75,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}\n{percentage}',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie {...config} />;
};




export default Pei_plot
